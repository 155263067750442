'use client';

import { Sidebar } from 'flowbite-react';
import { IoSettingsOutline } from "react-icons/io5";
import { FiPlusSquare } from "react-icons/fi";
import { RiCloudLine } from "react-icons/ri";
import { MdDriveFolderUpload } from "react-icons/md";
import { TbTemplate } from "react-icons/tb";
import { RiQuestionLine } from "react-icons/ri";
import { RiServiceLine } from "react-icons/ri";
import { RiUserLine } from "react-icons/ri";
import { RiLogoutBoxLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import '../../../stylesheets/styles.css'

const sidebarTheme = {
    collapse: {
        button: 'group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700',
        icon: {
            base: 'h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white',
            open: {
                off: '',
                on: 'text-gray-900',
            },
        },
        label: {
            base: 'ml-3 flex-1 whitespace-nowrap text-left',
            icon: {
                base: 'h-6 w-6 transition ease-in-out delay-0',
                open: {
                    on: 'rotate-180',
                    off: '',
                },
            },
        },
        list: 'space-y-2 py-2',
    },
    cta: {
        base: 'mt-6 rounded-lg p-4 bg-transparent ',
        color: {
            blue: 'bg-cyan-50 dark:bg-cyan-900',
            dark: 'bg-dark-50 dark:bg-dark-900',
            failure: 'bg-red-50 dark:bg-red-900',
            gray: 'bg-alternative-50 dark:bg-alternative-900',
            green: 'bg-green-50 dark:bg-green-900',
            light: 'bg-light-50 dark:bg-light-900',
            red: 'bg-red-50 dark:bg-red-900',
            purple: 'bg-purple-50 dark:bg-purple-900',
            success: 'bg-green-50 dark:bg-green-900',
            yellow: 'bg-yellow-50 dark:bg-yellow-900',
            warning: 'bg-yellow-50 dark:bg-yellow-900',
        },
    },
    item: {
        base: 'flex items-center justify-center rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700',
        active: 'bg-gray-100 dark:bg-gray-700',
        collapsed: {
            insideCollapse: 'group w-full pl-8 transition duration-75',
            noIcon: 'font-bold',
        },
        content: {
            base: 'px-3 flex-1 font-semibold whitespace-nowrap',
        },
        icon: {
            base: 'h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white',
            active: 'text-gray-700 dark:text-gray-100',
        },
        label: '',
        listItem: '',
    },
    items: {
        base: '',
    },
    itemGroup: {
        base: 'mt-4 space-y-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700',
    },
    logo: {
        base: 'mb-5 flex items-center pl-2.5',
        collapsed: {
            on: 'hidden',
            off: 'self-center whitespace-nowrap text-xl font-semibold dark:text-white',
        },
        img: 'mr-3 h-6 sm:h-7',
    },
};

const SidebarComponent = () => {
  const navigate = useNavigate();
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }


  const handleLogout = async () => {
    try {
      const response = await fetch('/logout/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      });

      if (response.ok) {
        navigate('/');
        window.location.reload();
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <Sidebar theme={sidebarTheme} style={{ maxWidth: '12rem', maxHeight: '31rem', marginRight: '1rem' }}>
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <Sidebar.Item as="button" onClick={() => navigate("/app/jobs/new?step=0")} icon={FiPlusSquare}>
            New Campaign
          </Sidebar.Item>
        </Sidebar.ItemGroup>
        <Sidebar.ItemGroup>
          <Sidebar.Item as="button" onClick={() => navigate("/app/jobs")} icon={IoSettingsOutline}>
            My Campaigns
          </Sidebar.Item>
          <Sidebar.Item as="button" onClick={() => navigate("/app/templates")} icon={TbTemplate}>
            My Templates
          </Sidebar.Item>
          <Sidebar.Item as="button" onClick={() => navigate("/app/accounts")} icon={RiCloudLine}>
            My Accounts
          </Sidebar.Item>
          <Sidebar.Item as="button" onClick={() => navigate("/app/user-files")} icon={MdDriveFolderUpload}>
            My Files
          </Sidebar.Item>
        </Sidebar.ItemGroup>
        <Sidebar.ItemGroup>
          <Sidebar.Item as="button" onClick={() => navigate("/app/support")} icon={RiQuestionLine}>
            Contact
          </Sidebar.Item>
          <Sidebar.Item as="button" onClick={() => navigate("/app/affiliate")} icon={RiServiceLine}>
            Affiliate
          </Sidebar.Item>
          <Sidebar.Item as="button" onClick={() => navigate("/app/profile")} icon={RiUserLine}>
            Profile
          </Sidebar.Item>
        </Sidebar.ItemGroup>
        <Sidebar.ItemGroup>
        <Sidebar.Item as="button" onClick={handleLogout} icon={RiLogoutBoxLine}>
            Sign Out
          </Sidebar.Item>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}

export default SidebarComponent;
