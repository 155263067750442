import React, { useState } from "react";
import { flushSync } from "react-dom";
import {
  Paper,
  Box,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { RiMailSendLine } from "react-icons/ri";
import { PrimaryButton } from "../../../../styled-components/Buttons";
import Options from "./Options";
import SmsPreview from "./SmsPreview";
import { useNewCampaign } from "../../../../contexts/NewCampaignContext";

function PreviewSmsPanel({ smss, row_count }) {
  const { setJobName, sendJobData } = useNewCampaign();
  const [loadingBeforeRunJob, setLoadingBeforeRunJob] = useState(false);

  // Function to handle sending job data and managing the loading state
  const handleSendJobData = async () => {
    try {
      // Force the UI to render the loading state before continuing
      flushSync(() => {
        setLoadingBeforeRunJob(true);
      });

      // Wait for the sendJobData to complete
      await sendJobData();
    } catch (error) {
      console.error("Error sending job data:", error);
    } finally {
      // Set loading to false after the job is done
      setLoadingBeforeRunJob(false);
    }
  };

  const handleCampaignNameChange = (event) => {
    setJobName(event.target.value);
  };

  // Render the loading state
  if (loadingBeforeRunJob) {
    return (
      <Paper
        sx={{ width: "100%", height: "100vh", overflow: "hidden", padding: "1rem" }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  // Render the main panel when not loading
  return (
    <Paper elevation={3} sx={{ padding: 2, margin: 2, width: "100%" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flexBasis="50%" sx={{ paddingRight: 2 }}>
          <Typography variant="h2" component="h2">
            New Campaign
          </Typography>
          <Typography variant="subtitle1" component="div">
            Everything looks good?
          </Typography>
        </Box>

        <Box
          flexBasis="50%"
          sx={{
            paddingLeft: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <TextField
            label="Campaign Name (optional)"
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleCampaignNameChange}
          />
        </Box>
      </Box>
      <Options />
      <SmsPreview smss={smss} />
      <Box display="flex" justifyContent="flex-end">
        <PrimaryButton
          variant="contained"
          startIcon={<RiMailSendLine />}
          sx={{ marginTop: 1 }}
          onClick={handleSendJobData} // Attach the new handler here
        >
          Send to {row_count} recipients
        </PrimaryButton>
      </Box>
    </Paper>
  );
}

export default PreviewSmsPanel;
