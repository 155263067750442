import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const JobDetailsContext = createContext();


export const useJobDetails = () => useContext(JobDetailsContext);


export const JobDetailsProvider = ({ children }) => {
    const navigate = useNavigate()
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [enableRealTime, setEnableRealTime] = useState(false)
    const [data, setData] = useState({})
    const [isButtonPause, setIsButtonPause] = useState(false)
    const [jobState, setJobState] = useState('')
    const cardStyle = {
      padding: "2rem", // p-6 em Tailwind
      display: "flex",
      flexDirection: "column",
      minWidth: 295.17,
      minHeight: 205.95,
      maxWidth: 345,
      alignItems: "center",
      textAlign: "center",
      borderRadius: "0.5rem", // rounded-lg em Tailwind // border-2 e border-gray-125 em Tailwind
      cursor: "pointer",
    };
  
    const imgStyle = {
      maxWidth: "45px",
      maxHeight: "45px",
      width: "auto",
      height: "auto",
      objectFit: "contain",
      display: "block",
      margin: "0 auto",
    };
    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

            break;
          }
        }
      }
      return cookieValue;
    }
    const csrftoken = getCookie("csrftoken");
    const recordError = async (errorMessage, apiUrl, requestBody) => {
      try {
        const errorRecordUrl = 'https://postsheet.com/api/error-record';
        await fetch(errorRecordUrl, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({
            error_message: errorMessage,
            api_url: apiUrl,
            request: requestBody,
          }),
        });
      } catch (err) {
        console.error('Failed to record the error:', err);
        navigate('/app/error', { state: { errorMessage: err.message } });
      }
    };
    const getJobAccountsDetails = async (dataSourceAccountId, actionAccountId, jobId) => {

      try {
        const accountIds = {
          data_source_account_id: dataSourceAccountId,
          action_account_id: actionAccountId
        }

        const url = 'https://postsheet.com/api/job/details/accounts'
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify(accountIds) 
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        return data;
      } catch(error)  {
        setError(error);
        recordError(error.message, 'https://postsheet.com/api/job/details/accounts', { dataSourceAccountId, actionAccountId });
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    }
    const getJobTemplateDetails = async (jobId) => {
      try {
    

        const url = 'https://postsheet.com/api/job/details/get-template'
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({job_id: jobId}) 
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
       
        return data;
      } catch(error)  {
        setError(error);
        recordError(error.message, 'https://postsheet.com/api/job/details/get-template', { job_id: jobId });
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    }
    const pauseCampaign = async (jobId) => {
     
      setIsButtonPause(true)
      setJobState('paused')
      try {
        const url = 'https://postsheet.com/api/job/details/pause';
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({ job_id: jobId }) // Pass job ID
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // setEnableRealTime(false)
        return data;
        
      } catch (error) {
        setError(error);
        recordError(error.message, 'https://postsheet.com/api/job/details/pause', { job_id: jobId });
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };
    
    const cancelCampaign = async (jobId) => {
   
      setJobState('cancelled')
      setEnableRealTime(false)
      try {
        const url = 'https://postsheet.com/api/job/details/cancel';
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({ job_id: jobId }) // Pass job ID
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setJobState('cancelled')
        setEnableRealTime(false)
        return data;
      } catch (error) {
        setJobState('paused')
        setError(error);
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };
    
    const getPendingJobItems = async (jobId) => {
      try {
        const url = 'https://postsheet.com/api/get_pending_job_items';
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({ job_id: jobId }) // Pass job ID
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.pending_items;
      } catch (error) {
        setError(error);
        recordError(error.message, 'https://postsheet.com/api/job/details/cancel', { job_id: jobId });
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const updateState = (stateSetter, value) => {
      return new Promise((resolve) => {
        stateSetter(value);  // Update the state
        resolve();  // Immediately resolve the promise
      });
    };
    
    const runCampaign = async (jobId) => {
      // Wait for states to be updated
      await updateState(setIsButtonPause, false);
      setIsButtonPause(false)
      await updateState(setJobState, 'running');
      setJobState('running')
    
      await updateState(setEnableRealTime, true);
  
      try {
    


        setJobState('running')
        // Fetch processed and failed emails
        const fetchResponse = await fetch(`https://postsheet.com/api/job/details/get_emails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({ job_id: jobId })
        });
        setJobState('running')
        if (!fetchResponse.ok) {
          throw new Error('Failed to fetch emails');
        }
        setJobState('running')
        const { processed_items, state } = await fetchResponse.json();
      
        const batchSize = 5;
        let allBatchesSent = true;
        setJobState('running')
   
        for (let i = 0; i < processed_items.length; i += batchSize) {
         
          setJobState('running')
          
          if (isButtonPause === true && state != 'running') {
            setJobState('paused');
            break;
          }
          if (jobState !== 'paused' || jobState !== 'cancelled' || state == 'running') {
            const emailBatch = processed_items.slice(i, i + batchSize);
            const isFirstBatch = i === 0;
            const isLastBatch = (i + batchSize) >= processed_items.length;
            
            const sendResponse = await fetch('https://postsheet.com/api/job/details/run', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
              },
              body: JSON.stringify({ job_id: jobId, processed_items: emailBatch, is_first_batch: isFirstBatch, is_last_batch: isLastBatch })
            });
    
            if (!sendResponse.ok) {
              throw new Error('Failed to run campaign');
            }
    
            const result = await sendResponse.json();
        
            await new Promise(resolve => setTimeout(resolve, 500));
            if (result.message === 'stop') {
              break;
            }
    
             // Delay between batches
          } else {
            break;
          }
        }
    
      } catch (error) {
     
        setJobState('paused');
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };
  //   const fetchJobDetails = async (job_uid) => {
  //     console.log("Entering fetch JobDetails");
  //     try {
  //         const url = 'https://postsheet.com/api/job/details/get-details';
  //         const response = await fetch(url, {
  //             method: 'POST',  // Change to POST
  //             credentials: 'include',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({ job_uid })  // Correctly format the body as JSON
  //         });
  //         console.log("RESPONSE", response);
  //         if (!response.ok) {
  //             throw new Error('Network response was not ok');
  //         }
  //         const data = await response.json();
  //         console.log("JOBS DATA", data);
  //         return data;
  //     } catch (error) {
  //         setError(error);
  //     } finally {
  //         setLoading(false);
  //     }
  // };
    return (
      <JobDetailsContext.Provider value={{ ...data, loading, error, cardStyle, imgStyle, getJobAccountsDetails, jobState, setJobState, data, setData, cancelCampaign,  pauseCampaign, getPendingJobItems, runCampaign, enableRealTime, setEnableRealTime, setIsButtonPause, isButtonPause, getJobTemplateDetails }}>
        {children}
      </JobDetailsContext.Provider>
    );
  };
  