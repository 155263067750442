const Head = () => {
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
 
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href="/apple-touch-icon-57x57-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="/apple-touch-icon-114x114-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="/apple-touch-icon-72x72-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="/apple-touch-icon-144x144-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href="/apple-touch-icon-60x60-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="/apple-touch-icon-120x120-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="/apple-touch-icon-76x76-precomposed.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="/apple-touch-icon-152x152-precomposed.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="196x196"
        href="/favicon-196x196.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="128x128"
        href="/favicon-128.png"
      />
      <meta name="application-name" content="PostSheet" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="/mstile-70x70.png" />
      <meta
        name="msapplication-square150x150logo"
        content="mstile-150x150.png"
      />
      <meta
        name="msapplication-wide310x150logo"
        content="/mstile-310x150.png"
      />
      <meta
        name="msapplication-square310x310logo"
        content="/mstile-310x310.png"
      />
    </>
  );
};

export default Head;
