import React from 'react';
import { Box, Typography, Paper } from "@material-ui/core";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { H2 } from '../../../styled-components/Typography';
import { PrimaryButton } from '../../../styled-components/Buttons';

const Highlight = styled.span`
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: rgb(252, 87, 47);
  text-decoration-thickness: 2px;  // Adjust the thickness of the underline as needed
`;

const StartEarning = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/affiliate-program');
  };

  return (
    <Paper style={{ width: "100%", padding: "20px", boxSizing: 'border-box', maxHeight:'250px' }}>
      <Box style={{ width: "100%" }}>
        <H2>Affiliate</H2>
        <br />
        <br />
        <Typography>
          Introduce PostSheet to your friends, followers, and coworkers, and earn&nbsp;
          <Highlight>25%</Highlight>
          &nbsp;recurring commission on every sale.
        </Typography>
        <br />
        <br />
        <PrimaryButton onClick={handleButtonClick}>Start Earning</PrimaryButton>
      </Box>
    </Paper>
  );
};

export default StartEarning;
