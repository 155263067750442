import { useState, useEffect } from 'react';
import { useJobDetails } from '../contexts/JobDetailsContext';

export function useJobStatePolling(jobId, enableRealTime, initialJobState) {
  const {jobState, setJobState} = useJobDetails()

  useEffect(() => {
      const fetchJobState = async () => {

          try {
              const url = `https://postsheet.com/api/state/${jobId}/`;
              const response = await fetch(url, {
                  method: 'GET',
                  credentials: 'include',
                  headers: {
                      'Content-Type': 'application/json',
                  }
              });

              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }

              const data = await response.json();

              setJobState(data.state);

              // Stop polling if job state is completed or paused
              if (data.state === 'completed'  || data.state === 'scheduled' || data.state === 'completed_with_fail' || data.state === 'paused') {
                  clearInterval(interval);
              }
          } catch (error) {
              console.error("Error fetching job state:", error);
          }
      };

      const interval = setInterval(fetchJobState, 1000); // Poll every 1 second
      return () => clearInterval(interval);
  }, [jobId], jobState);

  return jobState;
}