import { Box, Paper, Grid, Card, CardActionArea, CardMedia, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Snackbar, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccounts } from "../../../contexts/AccountsContext";
import { H2 } from "../../../styled-components/Typography";

const Accounts = () => {
  const { user_accounts, supported_accounts, available_accounts, handleAccountSelection, loading } = useAccounts();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const unsupportedTypes = ['mailgun', 'aws', 'smtp', 'sendgrid', 'mandrill', 'postmark', 'mailjet', 'outlook'];

  const handleMouseEnter = (e) => {
    const target = e.currentTarget;
    target.style.borderColor = '#fc572f';
  };

  const handleMouseLeave = (e) => {
    const target = e.currentTarget;
    target.style.borderColor = '#E5E7EB';
  };

  const handleTypeClick = (type, id) => {
    if (type.toLowerCase() === 'google') {
      setAlertMessage('Your Google account is automatically handled by PostSheet and cannot be edited!');
      setOpenAlert(true);
    } else if (unsupportedTypes.includes(type.toLowerCase())) {
      setAlertMessage('This account is no longer supported by PostSheet!');
      setOpenAlert(true);
    } else {
      navigate(`/app/accounts/add/${type}?account_id=${id}`);
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const cardStyle = {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0.5rem',
    border: '2px solid #E5E7EB',
    cursor: 'pointer',
    transition: 'border-color 0.25s',
  };

  // Adjusted to increase the image size
  const imgStyle = {
    maxWidth: '100px',  // Increased size
    maxHeight: '100px', // Increased size
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
    display: 'block',
    margin: '0 auto',
  };

  const hoverStyle = {
    borderRadius: '4px',
    transition: 'box-shadow 0.3s',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',
    }
  };

  const handleCardAccountClick = (key) => {
    const path = handleAccountSelection(key);
    navigate(path);
  };

  if (loading) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: "100%" }}>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box style={{ width: "100%" }}>
        <TableContainer component={Paper} style={{ padding: '1.5rem' }}>
          <H2>My Accounts</H2>
          <Table aria-label="customizable table">
            <TableHead>
              <TableRow>
                <TableCell align='left'>Name</TableCell>
                <TableCell align='left'>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user_accounts.length > 0 ? (
                user_accounts.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell align='left'>
                      {row.name}
                    </TableCell>
                    <TableCell align='left'>
                      <span
                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                        onClick={() => handleTypeClick(row.type, row.id)}
                      >
                        {capitalizeFirstLetter(row.type)}
                      </span>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No accounts found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Paper style={{ width: '100%', padding: '20px', marginTop: '20px' }}>
        <H2>Add an account</H2>
        <br />
        <Grid container spacing={8} justifyContent="center" alignItems="center">
          {available_accounts.map(accountType => (
            <Grid item xs={12} sm={6} md={3} key={accountType.key}>
              <Card style={cardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleCardAccountClick(accountType.key)}>
                <CardActionArea style={hoverStyle}>
                  <CardMedia
                    component="img"
                    alt={accountType.title}
                    style={imgStyle}
                    image={`../../static/assets/images/accounts/${accountType.key}.svg`}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default Accounts;
