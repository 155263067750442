import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '../../../../../components/CKEditor/build/ckeditor';
import { useEditorContext } from '../../../../../contexts/EditorContext';
import '../../ActionConfig/Editor/editor.css'
import { useNewCampaign } from '../../../../../contexts/NewCampaignContext';
const TextEditor = () => {

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <CKEditor
        editor={Editor}
        data="<p>Hello from CKEditor 5!</p>"
        config={{
          height: '300px',
        }}     
      />
    </div>
  );
};

export default TextEditor;
