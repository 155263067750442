import React from 'react'
import { Dialog, DialogTitle, DialogContent,Button, DialogActions } from '@mui/material';
import TextEditor from './CKEditor';

const EditorModal = ({openModal, setOpenModal}) => {
  return (
    <Dialog
  open={openModal}
  onClose={() => setOpenModal(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Edit a custom message"}</DialogTitle>
  <DialogContent>
    <TextEditor/>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => {
        setOpenModal(false);;
      }}>Cancel</Button>   
    <Button onClick={() => {
      // Lógica para "Cancel"
      setOpenModal(false);
    }} color="secondary">
      Done
    </Button>
  </DialogActions>
</Dialog>

  )
}

export default EditorModal