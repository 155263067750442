
import React from 'react';
import Footer from "../../../components/common/Footer/footer"
import { Button } from 'flowbite-react';
import './index.css'
import { useAuth } from '../../../contexts/AuthContext';
const TrialEnded = () => {
    

  return (
    <>

        <section className="panel">
          <h2 className="h2">Oops! </h2>
          <h3 className="font-bold text-lg mt-1">Your plan access has ended</h3>
          <br /><br />
          <p className="error">
            We're sorry, your trial access to the Growth Plan has ended. Please upgrade your account for more sends.
          </p>
          <br /><br /><br />
          <a href="/pricings" className="button button-secondary">Upgrade</a>
          <br /><br /><br />
        </section>

    </>
  );
};

export default TrialEnded