import React, { useState, useRef } from "react";
import {
  Paper,
  Typography,
  Box,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useAuth } from "../../../../../contexts/AuthContext";
import { H2, SubHeader, TextSimple } from "../../../../../styled-components/Typography";
import { PrimaryButton } from "../../../../../styled-components/Buttons";

function CSVUploadPanel({ onFileSelected, checkFileExists }) {
  const [fileName, setFileName] = useState("");
  const { isAuthenticated } = useAuth();
  const [warning, setWarning] = useState(true);

  const handleFileSelect = (file) => {
    setFileName(file.name);
    setWarning(false); // Clear warning once a file is selected
    onFileSelected(file);
    checkFileExists(file, onFileSelected);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const fileInputRef = useRef(null);

  if (!isAuthenticated) {
    return <Typography>Você precisa estar autenticado para acessar esta página.</Typography>;
  }

  const steps = [
    "Find your CSV file on your computer.",
    "Make sure it is properly formatted.",
    <>
      Your CSV's delimiter <b>MUST</b> be a comma ( , ).
    </>,
    <>
      If a cell in your CSV contains <b>line breaks</b>, the content of that
      cell should be enclosed in <b>quotes</b>.
    </>,
    "Click 'Choose File' and select your CSV file.",
    "Click 'Next' to upload your file and choose your action.",
    "Your file is automatically added to your files.",
  ];

  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      <H2>New Campaign</H2>
      <SubHeader>Where is your CSV?</SubHeader>
      <Box sx={{ my: 2 }}>
        <Box sx={{ width: "100%" }}>
          <List>
            {steps.map((step, index) => (
              <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <TextSimple variant="body1">{index + 1}.</TextSimple>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <TextSimple variant="body2" component="span">
                      {step}
                    </TextSimple>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ mt: 2 }}>
          <PrimaryButton variant="contained" component="label">
            Choose File
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".csv"  // Restrict file type to CSV
            />
          </PrimaryButton>
          {fileName && (
            <>
              <Typography sx={{ mt: 2 }}>
                Your CSV: <strong>{fileName}</strong>
              </Typography>
              <Box sx={{ mt: 2, p: 2, backgroundColor: '#d4edda', borderRadius: '4px' }}>
                <Typography sx={{ color: '#155724' }}>
                  Click next to proceed.
                </Typography>
              </Box>
            </>
          )}
          {!fileName && warning && (
            <Box sx={{ mt: 2, p: 2, backgroundColor: '#fde9e2', borderRadius: '4px' }}>
              <Typography sx={{ color: '#8a3c09' }}>
                Please select a file to proceed. The 'Next' button will be enabled as soon as your spreadsheet has been loaded and validated.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

export default CSVUploadPanel;
