const config = {
    stripe: {
      plans: {
        starter_monthly: 'starter_monthly_stripe_id',
        starter_yearly: 'starter_yearly_stripe_id',
        basic_monthly: 'basic_monthly_stripe_id',
        basic_yearly: 'basic_yearly_stripe_id',
        pro_monthly: 'pro_monthly_stripe_id',
        pro_yearly: 'pro_yearly_stripe_id',
        app_sumo_basic_yearly: 'app_sumo_basic_yearly_stripe_id'
      }
    }
  };
  
  export const products = {
    starter: {
      id: "starter",
      name: "Starter",
      quota: 1000,
    },
    basic: {
      id: "basic",
      name: "Growth",
      quota: 50000,
    },
    pro: {
      id: "pro",
      name: "Pro",
      quota: 250000,
    },
  };
  
  export const plans = [
    {
      id: "starter_monthly",
      product: products.starter,
      billing_interval: "month",
      price: "9",
      stripe_plan_id: config.stripe.plans.starter_monthly,
    },
    {
      id: "starter_yearly",
      product: products.starter,
      billing_interval: "year",
      price: "90",
      stripe_plan_id: config.stripe.plans.starter_yearly,
    },
    {
      id: "basic_monthly",
      product: products.basic,
      billing_interval: "month",
      price: "49",
      stripe_plan_id: config.stripe.plans.basic_monthly,
    },
    {
      id: "basic_yearly",
      product: products.basic,
      billing_interval: "year",
      price: "490",
      stripe_plan_id: config.stripe.plans.basic_yearly,
    },
    {
      id: "pro_monthly",
      product: products.pro,
      billing_interval: "month",
      price: "99",
      stripe_plan_id: config.stripe.plans.pro_monthly,
    },
    {
      id: "pro_yearly",
      product: products.pro,
      billing_interval: "year",
      price: "990",
      stripe_plan_id: config.stripe.plans.pro_yearly,
    },
    {
      id: "app_sumo_basic_yearly",
      product: products.basic,
      billing_interval: "lifetime",
      price: "AppSumo",
      stripe_plan_id: config.stripe.plans.app_sumo_basic_yearly,
    },
    {
      id: "app_sumo_basic_yearly",
      product: products.basic,
      billing_interval: "lifetime",
      price: "AppSumo",
      stripe_plan_id: config.stripe.plans.app_sumo_basic_yearly,
    },
  ];
  