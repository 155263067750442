
import React, { useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, TablePagination, DialogActions
} from '@mui/material';

const ITEMS_PER_PAGE = 7;

const FileDetailsModal = ({ isOpen, onClose, selectedFileDetails, page, setPage }) => {
  // Manipula a mudança de página
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>File Details</DialogTitle>
      <DialogContent>
      <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          {selectedFileDetails?.columns.map((column, index) => (
            <TableCell key={index}>{column.name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedFileDetails?.rows.slice(page * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE + ITEMS_PER_PAGE).map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.values.map((value, valueIndex) => (
              <TableCell key={valueIndex}>{value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  <TablePagination
    component="div"
    count={selectedFileDetails?.row_count || 0}
    rowsPerPage={ITEMS_PER_PAGE}
    page={page}
    onPageChange={handleChangePage}
    rowsPerPageOptions={[ITEMS_PER_PAGE]} // Desabilita a seleção de 'rows per page'
  />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileDetailsModal;