import { LinearProgress } from "@mui/material";
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? 'rgb(252, 87, 47)' : '#308fe8',
    },
  }));