import React from "react";
import {
  Paper,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import { RiMailSendLine } from "react-icons/ri";
import { PrimaryButton } from "../../../../styled-components/Buttons";
import EmailPreview from "../../new_job/Preview/EmailPreview";
import { useNewCampaign } from "../../../../contexts/NewCampaignContext";
import SmsPreview from "../../new_job/Preview/SmsPreview";

function PreviewPanel({ emails, row_count, action_type }) {
  const { setJobName, sendJobData } = useNewCampaign();

  const handleCampaignNameChange = (event) => {
    setJobName(event.target.value);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, margin: 2, width: "100%" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flexBasis="50%" sx={{ paddingRight: 2 }}>
          <Typography variant="h2" component="h2">
            New Campaign
          </Typography>
          <Typography variant="subtitle1" component="div">
            Everything looks good?
          </Typography>
        </Box>
        <Box
          flexBasis="50%"
          sx={{
            paddingLeft: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
        </Box>
      </Box>
      {action_type == 'gmail.send-email' ? (<EmailPreview emails={emails} />) : (<SmsPreview smss={emails}/>) } 
      
      
    </Paper>
  );
}

export default PreviewPanel;
