import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StartEarning from './StartEarning';
import Affiliate from './Affiliate';

const AffiliateRoute = () => {
  const [affiliateStatus, setAffiliateStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({})
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const fetchUserData = async () => {
    try {
      const response = await fetch('https://postsheet.com/api/user'); 
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const userData = await response.json();

      return userData;
    } catch (error) {
      console.error('Error fetching user data:', error);
      recordError(error.message, 'https://postsheet.com/api/user', null);
      navigate('/app/error', { state: { errorMessage: error.message } });
      return null;
    }
  };
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheet.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken, 
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  useEffect(() => {
    const checkAffiliateStatus = async () => {
      try {
        const userData = await fetchUserData();
        
   
        if (userData) {
            setUser(userData.user)
          setAffiliateStatus(userData.user.affiliate_status);
        } else {
          throw new Error('User data not found');
        }
      } catch (error) {
        setError(error);
        recordError(error.message, 'https://postsheet.com/api/user', null);

        navigate('/app/error', { state: { errorMessage: error.message } });
      } finally {
        setLoading(false);
      }
    };

    checkAffiliateStatus();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return affiliateStatus === 'affiliate' ? <Affiliate user={user} /> : <StartEarning />;
};

export default AffiliateRoute;
