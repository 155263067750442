import React, { useState } from 'react';
import './index.css';



const HowTo: React.FC = () => {
  const [selectedStep, setSelectedStep] = useState(0);

  const steps = [
    {
      title: 'Prepare your spreadsheet',
      description: 'Using Google Sheets or Airtable.',
      image: '../../static/assets/images/how-sheet.png',
    },
    {
      title: 'Write your message',
      description: 'And customize it using the data from your spreadsheet.',
      image: '../../static/assets/images/how-template.png',
    },
    {
      title: 'Sit back and relax',
      description:
        'While PostSheets crafts a personalized message for each recipient and sends it to them.',
      image: '../../static/assets/images/how-progress.png',
    },
  ];

  return (
    <section className="confined" style= {{marginBottom: '1.5rem'}} >
      <h1 className="title-how-it-works">How does it work?</h1>
      <h3 className="mt-2 text-xl text-gray-400 font-semibold lg:text-center">
        It's just as easy as sending a regular email.
      </h3>
      <br />
      <br />
      <br />

      <div className="flex justify-between lg:flex-col lg:items-center">
        <div className="mt-2" style={{ width: 260, marginRight: '15rem', marginLeft: '0rem'  }}>
          <ul className="how-it-works-list">
            {steps.map((step, index) => (
              <li key={step.title} className="mb-2">
                <button
                  id={`how-button-${index + 1}`}
                  onClick={() => setSelectedStep(index)}
                  className={`btn  ${
                    selectedStep === index ? 'selected-btn' : ''
                  }`}
                >
                  <span className="mr-1">{index + 1}.</span>
                  {step.title}
                </button>

                <p
                  id={`how-text-${index + 1}`}
                  className={`border-primary ml-4 mr-4 mt-6 mb-12 pl-4 font-semibold text-gray-700 border-l-4 border-primary ${
                    selectedStep !== index ? 'hidden lg:hidden' : ''
                  }`}
                >
                  {step.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:mt-20" style={{marginLeft:"15rem"}}>
          {steps.map((step, index) => (
            <img
              key={step.title}
              alt="how-to-img"
              id={`how-preview-${index + 1}`}
              src={step.image}
              className={`rounded-md shadow-big ${
                selectedStep !== index ? 'hidden' : ''
              }`}
              style={{ width: 600 }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowTo;
