import React, { useEffect, useState } from 'react';
import { TextField, Grid, Select, InputLabel, FormControl, MenuItem, Button, Collapse, IconButton } from '@mui/material';
import { useNewCampaign } from '../../../contexts/NewCampaignContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SecondaryButton } from '../../../styled-components/Buttons';

const EmailForm = ({ columns }) => {
  const { activeInputRef, setTemplateData, templateData, setAttachment } = useNewCampaign();
  const [attachmentName, setAttachmentName] = useState('');
  const [bccOpen, setBccOpen] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTemplateData(name, value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAttachment(file);
    setAttachmentName(file ? file.name : '');
  };

  const handleFocus = (event) => {
    activeInputRef.current = event.target;
  };

  const handleBccToggle = () => {
    setBccOpen((prev) => !prev);
  };


  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={2} style={{ marginTop: '2rem' }}>
        <Grid item xs={6}>
          <TextField
            label="From Address"
            value={templateData.fromAddress}
            name="fromAddress"
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="From Name"
            value={templateData.fromName}
            onChange={handleChange}
            onFocus={handleFocus}
            name="fromName"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="to-address-label">To Address</InputLabel>
            <Select
              labelId="to-address-label"
              value={templateData.toAddress}
              onChange={handleChange}
              onFocus={handleFocus}
              name="toAddress"
              required
            >
              {columns.map((column, index) => (
                <MenuItem key={index} value={`{{${column.name}}}`}>{`{{${column.name}}}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="To Name"
            value={templateData.toName}
            onChange={handleChange}
            onFocus={handleFocus}
            name="toName"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Subject"
            value={templateData.subject}
            onChange={handleChange}
            onFocus={handleFocus}
            name="subject"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            onClick={handleBccToggle}
            startIcon={<ExpandMoreIcon />}
          >
            Add BCC
          </Button>
          <Collapse in={bccOpen}>
            <FormControl fullWidth>
              <InputLabel id="bcc-label">BCC</InputLabel>
              <Select
                labelId="bcc-label"
                value={templateData.bcc}
                onChange={handleChange}
                onFocus={handleFocus}
                name="bcc"
                required
              >
                {columns.map((column, index) => (
                  <MenuItem key={index} value={`{{${column.name}}}`}>{`{{${column.name}}}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <SecondaryButton variant="contained" component="label">
            Attach File
            <input
              type="file"
              hidden
              onChange={handleFileChange}
            />
          </SecondaryButton>
          {attachmentName && <p>Attached: {attachmentName}</p>}
        </Grid>
      </Grid>
    </form>
  );
};

export default EmailForm;
