import React from 'react';
import { Button, Grid, Typography, Box, Paper, Card } from '@mui/material';

import { PrimaryButton } from '../../../../styled-components/Buttons';
import { H2, SemiBold, SubHeader } from '../../../../styled-components/Typography';

// Função para estilização


function ActionSelector({ actionChoices, handleActionSelect }) {



  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      <H2>New Campaign</H2>
      <SubHeader>What do you want to do?</SubHeader>
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          {actionChoices.map((action_choice, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
             <Card sx={{
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '0.5rem',
      cursor: 'pointer', 
      border: '2px solid rgb(229, 231, 235)',
      p: 2, // Adiciona um padding interno no card
    }}
    onClick={() => handleActionSelect(action_choice.key, action_choice.account_id)}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      
        transition: 'border-color 0.3s ease 0s',
      }}
      >
        <SemiBold>
        {action_choice.title}
        </SemiBold>
        <img src={`../../static/assets/images/accounts/${action_choice.requires_account_type}-icon.svg`}
                    alt={action_choice.title} style={{
          height: '2rem',
          maxWidth: '100%',
          display: 'block',
          verticalAlign: 'middle',
        }}/>
      </Box>
      <Typography variant="body2" sx={{
        color: 'rgb(73 69 66)',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        mt: 4, // margin-top: 1rem;
      }}>
        {capitalizeFirstLetter(action_choice.requires_account_type)}
      </Typography>
      <Typography variant="body2" sx={{
        color: 'rgb(73 69 66)',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        mt: 1, // margin-top: 0.25rem;
      }}>
        {action_choice.name}
      </Typography>
    </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      
    </Paper>
  );
}

export default ActionSelector;
