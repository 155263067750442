import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { SecondaryButton } from '../../../styled-components/Buttons'; // Assuming this is your custom button component

const QuotaExceededCard= () => {
  const handleUpgradeClick = () => {
    // Navigate to the upgrade page or handle the upgrade logic
    window.location.href = '/pricing'; // You can replace this with `useNavigate` if you're using React Router
  };

  return (
    <Card sx={{ maxWidth: 400, margin: '0 auto', padding: '2rem', textAlign: 'center' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          You're out of emails!
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Your email quota for the month has been exceeded. To continue sending emails, please upgrade your plan.
        </Typography>
        <Box mt={4}>
          <SecondaryButton onClick={handleUpgradeClick}>
            Upgrade
          </SecondaryButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default QuotaExceededCard;
