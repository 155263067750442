import './index.css'
import '../../../../../stylesheets/styles.css'
import { Link } from 'react-router-dom';

const FirstSection = () => {
  return (
    <section className="first-inside-main" style = {{marginLeft: 'auto', marginRight: 'auto', marginBottom: '1.5rem'}}>
      <div className="container-effortlessly">
        <h1 className="effortlessly" style={{ maxWidth: '30rem', marginRight: '15rem' }}>
          Effortlessly send personalized emails & text messages
        </h1>
        <div className="using">
          <span>Using</span>
          <span className="googlesheets">
            <img
              src="../../static/assets/images/data-sources/google.gsheets-icon.svg"
              className="googlesheets-icon"
              alt="Google Sheets"
            />
            Google Sheets
          </span>
          <span>or</span>
          <span className="googlesheets">
            <img
              src="../../static/assets/images/data-sources/airtable.svg"
              className="airtable-icon"
              alt="Airtable Logo"
            />
            Airtable
          </span>
        </div>
        <br />
        <br />

        <div className="gotodashboard-div">
          <Link to="/app" className="gotodashboard">
            Go to Dashboard &nbsp;→
          </Link>
        </div>
      </div>
      <img src="../../static/assets/images/flow.png" className="main-img" alt="Flow" />
    </section>
  );
};

export default FirstSection;
