import React from 'react';
import Footer from "../../../components/common/Footer/footer"
import { Button } from 'flowbite-react';
import './index.css'
const SignInComponent: React.FC = () => {
  return (
    <>
  
      <div className="container my-20 px-4 md:px-8">
        <div className="grid grid-cols-2 gap-4">
          <div className="confined">
            <h1 className="font-extrabold text-3xl leading-tight md:text-3xl" style={{ maxWidth: '30rem' }}>
              Don't forget to select all permissions on the Google authentication screen!
            </h1>
            <br />
            <p>
              Otherwise you won't be able to enjoy the features of <b>PostSheet</b>
            </p>
            <a href="https://postsheet.com/accounts/google/login/" className="button-primary my-20">Proceed to Login</a>
          </div>
          <div className="mt-4">
            <video
              id="video-new-job-action-configure-send-email"
              autoPlay
              loop
              playsInline
              muted
              className="shadow-semi-big rounded-lg overflow-hidden"
            >
              <source src="../../static/content/guides/sign-in-video-google-options.mp4" type="video/mp4" />
            </video>
            <div id="video-sign-in-video-google-options" className="w-0 mt-4 h-1 bg-gray-200 transition-all duration-1000"></div>
          </div>
        </div>
      </div>
      <div>
  
    </div>
    </>
  );
};

export default SignInComponent;
