import dayjs from 'dayjs';

const getDisabledIntervals = (scheduledJobs) => {
  let disabledIntervals = [];
  scheduledJobs.forEach(job => {
    const scheduledTime = dayjs(job.scheduled_date_time);

    const oneHourBefore = scheduledTime.subtract(1, 'hour').add(1, 'minute');
    const oneHourAfter = scheduledTime.add(1, 'hour').subtract(1, 'minute');

    disabledIntervals.push({
      from: oneHourBefore.toDate(),
      to: oneHourAfter.toDate()
    });
  });

  return disabledIntervals;
};

const isTimeDisabled = (time, disabledIntervals) => {
  return disabledIntervals.some(interval => {
    return time.isAfter(interval.from) && time.isBefore(interval.to);
  });
};
const findNextAvailableTime = (disabledIntervals) => {
    let nextAvailableTime = dayjs().add(1, 'day').hour(12).minute(0).second(0);
    while (isTimeDisabled(nextAvailableTime, disabledIntervals)) {
        nextAvailableTime = nextAvailableTime.add(60, 'minute');
    }
    return nextAvailableTime;
};

export const schedulingService = {getDisabledIntervals, isTimeDisabled, findNextAvailableTime};