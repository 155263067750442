import React, {
    useContext,
    useState,
    useEffect,
    useRef,
  } from "react";
  import {
    TextField,
    Grid,
    InputLabel,
    FormControl,
    MenuItem,
    Button,
    Collapse,
    IconButton,
    Select,
  } from '@mui/material';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import { SecondaryButton } from '../../../../styled-components/Buttons';
  import { useSheetsExtension } from "../SheetsExtensionContext";
  
  const EmailForm = ({ userEmail, columns }) => {
    const { activeInputRef, setEmailData, emailData, setAttachment } = useSheetsExtension();
  
    const [attachmentName, setAttachmentName] = useState('');
    const [bccOpen, setBccOpen] = useState(false);
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setEmailData(name, value);
    };
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setAttachment(file);
      setAttachmentName(file ? file.name : '');
    };
  
    useEffect(() => {
      setEmailData("fromAddress", userEmail);
    }, [userEmail]);
  
    const handleFocus = (event) => {
      activeInputRef.current = event.target;
    };
  
    const handleBccToggle = () => {
      setBccOpen((prev) => !prev);
    };
  
    return (
      <form noValidate autoComplete="off">
        <Grid container spacing={2} style={{ marginTop: '2rem', maxWidth: '290px'}}>
          <Grid item xs={12}>
            <TextField
              label="From Address"
              value={emailData.fromAddress}
              name="fromAddress"
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="From Name"
              value={emailData.fromName}
              onChange={handleChange}
              onFocus={handleFocus}
              name="fromName"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="to-address-label">To Address</InputLabel>
              <Select
                labelId="to-address-label"
                value={emailData.toAddress}
                name="toAddress"
                onChange={handleChange}
                onFocus={handleFocus}
                label="To Address"
                required
              >
                {columns.map((column, index) => (
                  <MenuItem key={index} value={`{{${column.name}}}`}>
                    {`{{${column.name}}}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="To Name"
              value={emailData.toName}
              onChange={handleChange}
              onFocus={handleFocus}
              name="toName"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Subject"
              value={emailData.subject}
              onChange={handleChange}
              onFocus={handleFocus}
              name="subject"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="text"
              onClick={handleBccToggle}
              startIcon={<ExpandMoreIcon />}
            >
              Add BCC
            </Button>
            <Collapse in={bccOpen}>
              <FormControl fullWidth>
                <InputLabel id="bcc-label">BCC</InputLabel>
                <Select
                  labelId="bcc-label"
                  value={emailData.bcc}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  label="BCC"
                  name="bcc"
                  required
                >
                  {columns.map((column, index) => (
                    <MenuItem key={index} value={`{{${column.name}}}`}>
                      {`{{${column.name}}}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton variant="contained" component="label">
              Attach File
              <input
                type="file"
                hidden
                onChange={handleFileChange}
              />
            </SecondaryButton>
            {attachmentName && <p>Attached: {attachmentName}</p>}
          </Grid>
        </Grid>
      </form>
    );
  };
  
  export default EmailForm;
  