import React from 'react';
import './index.css';

const LoginComponent = () => {
  const handleLoginClick = () => {
    window.open('https://postsheet.com/accounts/google/login/', '_blank');
  };

  return (
    <>
      <div className="container" style={{ padding: '20px', width: '100%' }}>
        <div className="grid grid-cols-1 gap-4">
          <div className="confined">
            <h1 className="font-bold text-lg leading-tight" style={{ maxWidth: '15rem' }}>
              Don't forget to select all permissions on the Google authentication screen!
            </h1>
            <p style={{ marginTop: '10px', fontSize: '14px' }}>
              Otherwise, you won't be able to enjoy the features of <b>PostSheet</b>
            </p>
            <button onClick={handleLoginClick} className="button-primary" style={{ display: 'block', marginTop: '20px', fontSize: '14px' }}>
              Proceed to Login
            </button>
          </div>

          <div className="mt-4">
            <video
              id="video-new-job-action-configure-send-email"
              autoPlay
              loop
              playsInline
              muted
              style={{ width: '100%', borderRadius: '8px' }}
            >
              <source src="../../static/content/guides/sign-in-video-google-options.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
