import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import './index.css';
import './menu.css';
import '../../../stylesheets/styles.css';
import { Link } from 'react-router-dom';

const Header = ({isAuthenticated, loading}) => {
  const [menuOpen, setMenuOpen] = useState(true);


  
  const [id, setId] = useState(null);
  const [uid, setUid] = useState(null);


  useEffect(() => {

  }, []);

  return (
    <div className="header">
      <div className="topbar">
        <div className="navbar">
          <a href="/" className="topbar-brand">
            <img src="../../static/assets/images/logo.svg" className="logo" alt="Logo" />
            <span className="postsheet">PostSheet</span>
          </a>
          <div className="top-menu">
            <div>
              <a
                id="menu-open"
                href="#!"
                className={`menu-icon ${!menuOpen ? 'md:inline' : 'hidden'}`}
                onClick={() => setMenuOpen(true)}
              >
                <ReactSVG
                  src="../../static/assets/images/menu-open.svg"
                  className="image w-7 h-7"
                />
              </a>
              <a
                id="menu-close"
                href="#!"
                className={`menu-icon ${menuOpen ? 'md:inline' : 'hidden'}`}
                onClick={() => setMenuOpen(false)}
              >
                <ReactSVG
                  src="../../static/assets/images/menu-close.svg"
                  className="burger-menu"
                />
              </a>
            </div>
            <div
              id="header-menu"
              className={`md:hidden ${menuOpen ? 'flex' : 'hidden'}`}
            >
              <div className="header-menu-align">
                <a href="/guides" className="menu-option">
                  Guides
                </a>
                <a href="https://postsheetblog.com" className="menu-option">
                  Blog
                </a>
                <a href="/pricing" className="menu-option">
                  Pricing
                </a>
                {loading ? (
                  <div className="menu-option">Loading...</div>
                ) : (
                  !isAuthenticated ? (
                    <Link to="/signin" className="menu-option">Sign In</Link>
                  ) : (
                    <Link to="/app/jobs/new?step=0" className="menu-option">Dashboard</Link>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
