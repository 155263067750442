import {createTheme} from '@mui/material/styles';


export const theme = createTheme({
  typography: {
    fontFamily: [
      "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
      "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
    ].join(','),
    h2: {
      fontWeight: 800,
      fontSize: '1.5rem' ,
      lineHeight: '2rem' 
    },
    h3: {
      fontWeight: 800,
      fontSize: '1.25rem', 
      lineHeight: '1.75rem' 
    },    
    h4: {
      fontWeight: 800,
      fontSize: '1.125rem',
      lineHeight: '1.75rem' 
    }
  },
  
});