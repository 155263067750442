import React, { createContext, useContext, useRef, useState } from 'react';

const EditorContext = createContext(null);

export const useEditorContext = () => useContext(EditorContext);

export const EditorProvider = ({ children }) => {
  const editorRef = useRef(null);
  const activeInputRef = useRef(null);
  const [emailData, setEmailDataInternal] = useState({
    fromAddress: '',
    fromName: '',
    toAddress: '',
    toName: '',
    subject: '',
    emailTemplate: ''  // Estado inicial para o conteúdo do CKEditor
  });

  const setEmailData = (name, value) => {
    setEmailDataInternal(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const handleInsertPlaceholder = (placeholder) => {
    const active = activeInputRef.current;
    const placeholderText = `{{${placeholder}}}`;

    if (active === editorRef.current) {
      // Inserção no CKEditor
      const model = active.model;
      const selection = model.document.selection;
      model.change(writer => {
        const insertPosition = selection.getFirstPosition();
        writer.insertText(placeholderText, insertPosition);
      });
      active.editing.view.focus();
    } else if (active) {
     
      // Inserção em campos de input
      const start = active.selectionStart;
      const end = active.selectionEnd;
      const text = active.value;
      const newText = text.substring(0, start) + placeholderText + text.substring(end);
   
      setEmailData(active.name, newText);

      setTimeout(() => {
        active.focus();
        active.selectionStart = active.selectionEnd = start + placeholderText.length;
      }, 0);
    }
  };

  return (
    <EditorContext.Provider value={{ editorRef, activeInputRef, handleInsertPlaceholder, emailData, setEmailData }}>
      {children}
    </EditorContext.Provider>
  );
};
