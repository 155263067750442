import React, { useState } from "react";
import {
  Button,
  Paper,
  TextField,
  Typography,
  Grid,
  Box,
  Link,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { H2, LinkSimple, SubHeader, TextSimple } from "../../../../../styled-components/Typography";
import { useNewCampaign } from "../../../../../contexts/NewCampaignContext";
// Assumindo que você tem um tema configurado

function GoogleSheetsUploadPanel({ onUrlChange }) {
  const [sheetUrl, setSheetUrl] = useState("");
  const {googleSheetUrl, actionConfig} = useNewCampaign()
  useState(googleSheetUrl || ""); 

  const handleUrlChange = (event) => {
    
    const url = event.target.value;
    setSheetUrl(url);
    onUrlChange(url); // Atualiza o estado no componente pai
  };
  const sampleSheetUrl = "https://docs.google.com/spreadsheets/d/1HSG5tWMyjg_psicQ3rBphWmF56TFwulWG_O_tCa4y-8/edit#gid=286059456";

  const handleUseSample = (e) => {
    e.preventDefault(); // Evita o comportamento padrão do link
    // Atualiza o estado para refletir a URL da planilha de amostra
    setSheetUrl(sampleSheetUrl);
    onUrlChange(sampleSheetUrl); 
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Implemente o processamento da URL aqui

  };
  const steps = [
    <>
      Open your{" "}
      <LinkSimple
        href="https://sheets.google.com"
        target="_blank"
        style={{ textDecoration: "underline", fontWeight: "bold" }}
      >
        Google Sheets
      </LinkSimple>{" "}
      document.
    </>,
    "Select the sheet that you want to use.",
    "Right click on the location bar and copy the address.",
    "Paste the address here.",
    
  ];
  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      <H2>New Campaign</H2>
      <SubHeader>Where is your spreadsheet?</SubHeader>
      <Box sx={{ my: 2 }}>
        <Box sx={{ width: "100%" }}>
          <List>
            {steps.map((step, index) => (
              <ListItem key={index} sx={{ py: 0.5, px: 0 }}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <TextSimple variant="body1">{index + 1}.</TextSimple>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <TextSimple variant="body2" component="span">
                      {step}
                    </TextSimple>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }} >
          <TextField
            fullWidth
            variant="outlined"
            label="Your Google spreadsheet URL"
            value={sheetUrl}
            onChange={handleUrlChange}
            required
            sx={{ mt: 2 }}
          />
        </Box>
        <Typography sx={{ mt: 2 }}>
        <LinkSimple onClick={handleUseSample} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
    Use Sample Spreadsheet
  </LinkSimple>{" "}
        </Typography>
        {actionConfig.columns && actionConfig.columns.length > 0 ? (
  <>

    <Box sx={{ mt: 2, p: 2, backgroundColor: '#d4edda', borderRadius: '4px' }}>
      <Typography sx={{ color: '#155724' }}>
        Click next to proceed.
      </Typography>
    </Box>
  </>
) : (
  <Box sx={{ mt: 2, p: 2, backgroundColor: '#fde9e2', borderRadius: '4px' }}>
    <Typography sx={{ color: '#8a3c09' }}>
      The 'Next' button will be enabled as soon as your spreadsheet has been loaded and validated.
    </Typography>
  </Box>
)}
      </Box>
    </Paper>
  );
}

export default GoogleSheetsUploadPanel;
