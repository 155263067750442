import { styled } from '@mui/system';
import Button from '@mui/material/Button';

export const SecondaryButton = styled(Button)({
  backgroundColor: 'rgb(61, 56, 53)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgb(252, 87, 47)',
  },
});

export const PrimaryButton = styled(Button)({
  backgroundColor: 'rgb(252, 87, 47)',
  color: 'white',
  textTransform: 'none', // Desativa a transformação automática para maiúsculas
  padding: '8px 16px', // Aumenta o padding (ajuste os valores conforme desejado)
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
  fontSize: '0.96rem',
  fontWeight: '700', // Sombra sutil
  transition: 'transform 0.2s, box-shadow 0.2s', // Suaviza as transições de transform e box-shadow
  '&:hover': {
    backgroundColor: 'rgb(252, 87, 47)', // Mantém a cor de fundo ao passar o mouse
    transform: 'translateY(-2px)', // Levanta o botão sutilmente ao passar o mouse
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)', // Sombra um pouco mais proeminente ao passar o mouse
  },
});

export const SubSecondaryButton = styled(Button)({
  backgroundColor: 'rgb(156 152 149)',
  color: 'white',
  textTransform: 'none', // Desativa a transformação automática para maiúsculas
  padding: '8px 16px', // Aumenta o padding (ajuste os valores conforme desejado)
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
  fontSize: '0.96rem',
  fontWeight: '700', // Sombra sutil
  transition: 'transform 0.2s, box-shadow 0.2s', // Suaviza as transições de transform e box-shadow
  '&:hover': {
    backgroundColor: 'rgb(156 152 149)',
    transform: 'translateY(-2px)', // Levanta o botão sutilmente ao passar o mouse
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)', // Sombra um pouco mais proeminente ao passar o mouse
  },
});