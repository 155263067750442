// TextEditor.js
import React, { useEffect, useRef } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSheetsExtension } from "../SheetsExtensionContext";

const TextEditor = ({ userEmail }) => {
  const { editorRef, activeInputRef, emailData, setEmailData } = useSheetsExtension();
  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editorRef.current = editor;
    }
  }, []);

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <ReactQuill
        ref={quillRef}
        value={emailData.emailTemplate}
        onChange={(content, delta, source, editor) => {
          // Update emailData.emailTemplate whenever the content changes
          setEmailData('emailTemplate', content);
        }}
        onFocus={() => {
          // Set the activeInputRef to the Quill editor instance on focus
          activeInputRef.current = quillRef.current.getEditor();
        }}
        onBlur={() => {
          // Clear the activeInputRef when the editor loses focus
          activeInputRef.current = null;
        }}
        modules={{
          toolbar: [
            [{ font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
          ],
        }}
        formats={[
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'color',
          'background',
          'align',
        ]}
        placeholder="Start typing here..."
      />
    </div>
  );
};

export default TextEditor;
