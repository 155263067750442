import React, { useState, useMemo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, TextField } from '@mui/material';
import { H3 } from "../../../../../styled-components/Typography";
import { useNewCampaign } from "../../../../../contexts/NewCampaignContext";
import { schedulingService } from "../services/SchedulingService";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const Scheduler = ({ scheduledJobs }) => {
  const { setScheduledDate, scheduledDate } = useNewCampaign();
  const [startDate, setStartDate] = useState(scheduledDate ? new Date(scheduledDate) : null);
  const [userTimezone, setUserTimezone] = useState('');

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    setUserTimezone(timezone);
  }, []);

  const disabledIntervals = useMemo(() => {
    const intervals = schedulingService.getDisabledIntervals(scheduledJobs);

    return intervals;
  }, [scheduledJobs]);

  const handleDateChange = (date) => {
    if (!date) return;

    const localDateTime = dayjs(date);
    const utcDateTime = localDateTime.utc();
    const utcDate = utcDateTime.toDate();

    setScheduledDate(utcDate);
    setStartDate(date);
  };

  // const isDisabledDate = (date) => {
  //   // Simplified logic to disable specific dates
  //   const formattedDate = dayjs(date).format('YYYY-MM-DD');
  //   const isDisabled = disabledIntervals.some(interval => interval.date === formattedDate);
  //   console.log("Checking Date:", formattedDate, "Disabled:", isDisabled);
  //   return isDisabled;
  // };

  // const isDisabledTime = (time) => {
  //   // Simplified logic to disable specific times
  //   const formattedTime = dayjs(time).format('HH:mm');
  //   const isDisabled = disabledIntervals.some(interval => interval.time === formattedTime);
  //   console.log("Checking Time:", formattedTime, "Disabled:", isDisabled);
  //   return isDisabled;
  // };

  return (
    <Box sx={{ padding: 3, margin: 1 }}>
      <H3>Schedule A Job</H3>
      <p>Your Timezone: {userTimezone}</p> {/* Display the user's timezone */}
      <Box sx={{ marginTop: 4 }}>
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          showTimeSelect
          timeIntervals={15}
          minDate={new Date()}
          dateFormat="MMMM d, yyyy h:mm aa"
          customInput={<TextField />}
        />
      </Box>
    </Box>
  );
};

export default Scheduler;
