import React, { useState, useEffect } from 'react';
import {
  TableContainer, Paper, Table, TableHead, TableRow, TableCell,
  TableBody, Checkbox, Typography, Stack, Box, Grid, TableSortLabel,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { H2 } from '../../../styled-components/Typography';
import { PrimaryButton, SubSecondaryButton } from '../../../styled-components/Buttons';
import { format } from 'date-fns';

const tableContainerStyle = {
  maxHeight: '400px',
  overflowY: 'auto',
  margin: '20px 0',
  padding: '1.5rem'
};

const TemplateTable = ({ title, noDataText }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [openDelete, setOpenDelete] = useState(false); // State for the delete confirmation modal
  const navigate = useNavigate();

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheet.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await fetch('https://postsheet.com/api/templates/get', {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setTemplates(data.templates);
    } catch (error) {
      console.error('Error fetching templates:', error);
      recordError(error.message, 'https://postsheet.com/api/templates/get', null);
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };
  

  useEffect(() => {
    fetchTemplates();
  }, []);

  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Creation Date', field: 'creation_date_time' },
    { title: 'Default Data Source', field: 'data_source_type' },
    { title: 'Parent Campaign', field: 'parent_campaign' },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedRows = templates.map((template) => template.id);
      setSelectedRows(newSelectedRows);
      return;
    }
    setSelectedRows([]);
  };

  const handleCreateTemplateClick = () => {
    navigate('/app/templates/create'); // Redirect directly
  };

  const handleSelectRowClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelectedRows);
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  const handleRowClick = (uid) => {
    navigate(`/app/template/${uid}`);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatCreationDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return dateString;
    return format(date, 'MM-dd-yyyy hh:mm a');
  };

  const sortedTemplates = templates.slice().sort((a, b) => {
    if (orderBy) {
      const valueA = a[orderBy];
      const valueB = b[orderBy];
      if (valueA < valueB) return order === 'asc' ? -1 : 1;
      if (valueA > valueB) return order === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const handleDeleteSelectedFiles = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = async () => {

    const url = 'https://postsheet.com/api/templates/safe-delete/delete/clear';
    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ template_ids: selectedRows }), // Correct key name
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      fetchTemplates(); // Refresh the template list
      setSelectedRows([]);
      setOpenDelete(false);
      // Update state or perform other actions after successful deletion

    } catch (error) {
      console.error("Delete error:", error);
      recordError(error.message, url, { template_ids: selectedRows });

      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TableContainer component={Paper} style={tableContainerStyle}>
          {title && <H2>{title}</H2>}
          <Table aria-label="template table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedRows.length > 0 && selectedRows.length < templates.length}
                    checked={templates.length > 0 && selectedRows.length === templates.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all templates' }}
                  />
                </TableCell>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align || 'left'}
                    sortDirection={orderBy === column.field ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, column.field)}
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTemplates.length > 0 ? (
                sortedTemplates.map((template, rowIndex) => {
                  const isItemSelected = isSelected(template.id);
                  return (
                    <TableRow
                      key={template.id}
                      hover
                      onClick={() => handleRowClick(template.uid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleSelectRowClick(event, template.id)}
                          inputProps={{ 'aria-labelledby': `template-table-checkbox-${rowIndex}` }}
                        />
                      </TableCell>
                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex} align={column.align || 'left'}>
                          {column.field === 'creation_date_time'
                            ? formatCreationDate(template[column.field])
                            : template[column.field]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <Box mt={2}>
          <Stack direction="row" spacing={2}>
            <SubSecondaryButton onClick={handleDeleteSelectedFiles}>
              Delete Selected Files
            </SubSecondaryButton>
            <PrimaryButton onClick={handleCreateTemplateClick}>Create Template</PrimaryButton>
          </Stack>
        </Box>
      </Grid>
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected templates? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TemplateTable;
