import React from 'react';
import { Chip, Stack } from '@mui/material';
import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useNewCampaign } from '../../../../../contexts/NewCampaignContext';

const ColumnsChips = ({ columns }) => {
    const { handleInsertPlaceholder } = useNewCampaign();

    return (
        <Stack direction="row" spacing={1} style={{ marginTop: '1.5rem' }}>
            {columns.map((column, index) => (
                <Chip
                    key={index}
                    label={column.name}
                    onClick={() => handleInsertPlaceholder(column.name)}
                />
            ))}
        </Stack>
    );
};

export default ColumnsChips;