import React from 'react';
import './index.css';

import '../../../../../stylesheets/styles.css'

const TrustedBy: React.FC = () => {
  return (
    <section className="confined text-center" style = {{marginBottom: '1.5rem'}}>
      <h2 className="h1">
        These brands rely on us for reaching their audience.
      </h2>
      <br />
      <div className="flex flex-wrap justify-center">
        <img
          src="../../static/assets/images/trustedby/airbyte.svg"
          alt="Airbyte"
          className="transform mt-6 mx-2 scale-75 translate-y-2"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/appsumo.svg"
          alt="Appsumo"
          className="transform mt-6 mx-2 scale-75"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/grabr.svg"
          alt="Grabr"
          className="transform mt-6 mx-2 scale-75 translate-y-2"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/paysend.svg"
          alt="Paysend"
          className="transform mt-6 mx-2 scale-75 translate-y-2"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/peach.svg"
          alt="Peach"
          className="transform mt-6 mx-2 translate-y-4 scale-125"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/piktochart.svg"
          alt="Piktochart"
          className="transform mt-6 mx-2 translate-y-2"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/stanford.svg"
          alt="Stanford University"
          className="transform mt-6 mx-2 scale-75"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/swivl.svg"
          alt="Swivl"
          className="transform mt-6 mx-2 scale-75"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/techedge.svg"
          alt="Techedge"
          className="transform mt-6 mx-2 scale-75"
          style={{ width: '150px', height: '75px' }}
        />
        <img
          src="../../static/assets/images/trustedby/cuny.svg"
          alt="The City University of New York"
          className="transform mt-6 mx-2 scale
          -75"
          style={{ width: '150px', height: '75px' }}
        />
      </div>
    </section>
  );
};

export default TrustedBy;
