import { Chip, Stack } from '@mui/material';
import { useSheetsExtension } from '../SheetsExtensionContext';

const ColumnsChips = ({ columns, userEmail }) => {
  const { handleInsertPlaceholder } = useSheetsExtension();

  return (
    <Stack
      direction="row"
      spacing={1}
      style={{ marginTop: '1.5rem', maxWidth: '260px' }}
      sx={{ flexWrap: 'wrap' }} // Enable wrapping
    >
      {columns.map((column, index) => (
        <Chip
          key={index}
          label={column.name}
          onClick={() => handleInsertPlaceholder(column.name)}
        />
      ))}
    </Stack>
  );
};

export default ColumnsChips;
