import './index.css';
import '../../../stylesheets/styles.css'

const Footer = () => {
  return (
    <footer>
      <div className="footer-wrapper">
        <div className="left-footer">
          <span className="text-gray">By</span>{' '}
          <a
            href="https://twitter.com/joe_sheet"
            target="_blank"
            rel="noreferrer"
            className="link-simple"
          >
            @joe_sheet
          </a>{' '}
          <span className="text-gray">:)</span> <div className="wh-16"></div>
        </div>
        <div className="wh-16"></div>
        <div className="footer-right">
          <div className="wh-16"></div>
          <div>
            <a href="/guides" className="wh-16">
              <div className="mb-8 text-sm font-bold text-gray-500 uppercase">
                Guides
              </div>
            </a>
            <div className="mt-1">
              <div className="mt-1"></div>
              <a
                href="/guides/send-email-using-google-sheets"
                className="footer-right-option"
              >
                Send email using Google Sheets
              </a>
            </div>
            <div className="mt-1">
              <a
                href="/guides/send-email-using-airtable"
                className="footer-right-option"
              >
                Send email using Airtable
              </a>
            </div>
            <div className="mt-1">
              <a
                href="/guides/send-sms-using-google-sheets"
                className="footer-right-option"
              >
                Send SMS using Google Sheets
              </a>
            </div>
            <div className="mt-1">
              <a
                href="/guides/send-sms-using-airtable"
                className="footer-right-option"
              >
                Send SMS using Airtable
              </a>
            </div>
          </div>

          <div className="w20-h8"></div>

          <div>
            <div className="mb-4 text-sm font-bold text-gray-500 uppercase">
              More
            </div>
            <div className="mt-1">
              <a href="/affiliate" className="footer-right-option">
                Affiliate Program
              </a>
            </div>
            <div className="mt-1">
              <a href="/terms-of-service" className="footer-right-option">
                Terms of Service
              </a>
            </div>
            <div className="mt-1">
              <a href="/privacy-policy" className="footer-right-option">
                Privacy Policy
              </a>
            </div>
          </div>

          <div className="w20-h8"></div>

          <div>
            <div className="mb-4 text-sm font-bold text-gray-500 uppercase">
              Connect
            </div>
            <div className="mt-1">
              <a href="https://twitter.com/postsheethq" className="contact">
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"></path>
                  </g>
                </svg>
                <span>Twitter</span>
              </a>
            </div>
            <div className="mt-1">
              <a href="/contact" className="contact">
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm9.06 8.683L5.648 6.238 4.353 7.762l7.72 6.555 7.581-6.56-1.308-1.513-6.285 5.439z"></path>
                  </g>
                </svg>
                <span>Email</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
