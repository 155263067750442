import React from 'react';
import { ReactComponent as LineSvg } from './line.svg';

const LineIcon: React.FC = () => {
  return (
    <LineSvg
      className="text-gray-150 fill-current max-w-lg my-32 mx-auto"
      style={{ color: 'rgb(234 229 226)' }}
    />
  );
};

export default LineIcon;
