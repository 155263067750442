import React, { useEffect, useState } from 'react';
import { Paper, Box, CircularProgress } from '@mui/material';
import './index.css'; // Ensure this path is correct

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
  
  
    
        <section class="confined panel">
          <h1 class="h1">Privacy Policy</h1>
          <br /><br />
    
          <h2 class="h3 mb-auto">Summary</h2>
      <ul class="list-disc indent">
        <li>We will never sell or share your personal data with anyone. Period.</li>
        <li>Sign In with Google is required to access your spreadsheets and also to send emails from your Gmail address,
          if you choose to.</li>
        <li>We use a cryptographically signed cookie named “auth“ to store your login session data.</li>
        <li>If you visit our website though an affiliate link, we use a cookie named “ref_uid“ to store the affiliate's
          unique user identifier and another cookie named “ref_time“ to store the time of the visit.</li>
        <li>We do not use any third-party analytics service. Just regular web server logs (which include your IP address
          and User-Agent).</li>
        <li>If you share your email with us to receive product updates, it will only be used for that purpose. We will
          never send you spam.</li>
        <li>Tracking pixels are disabled by default and can only be enabled at the user's discretion.</li>
        <li>We use Stripe for payment, analytics, and other business services. Stripe collects identifying information
          about the devices that connect to its services. Stripe uses this information to operate and improve the
          services it provides to us, including for fraud detection. You can learn more about Stripe and read its
          privacy policy at <a href="https://stripe.com/privacy" class="link">https://stripe.com/privacy</a>.</li>
      </ul>
      <br /><br />

      <h2 class="h3 mb-auto">1. Introduction</h2>
      <p class="mb-auto">Our Privacy Policy governs your visit to <b>postsheet.com</b> (together or individually
        “Service”), and explains how we collect, safeguard and disclose information that results from your use of our
        Service operated by <b>La Purisima LLC</b> (“Company”, “we”, “our”, “us”).</p>
      <p class="mb-auto">We use your data to provide and improve Service. By using Service, you agree to the collection
        and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the
        terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
      <p class="mb-auto">Our Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy
        Policy constitutes your agreement with us (“agreement”).</p>

        <h2 class="h3 mb-auto">2. Definitions</h2>
<p class="mb-auto"><b>SERVICE</b> means the postsheet.com website operated by La Purisima LLC.</p>
<p class="mb-auto"><b>PERSONAL DATA</b> means data about a living individual who can be identified from those data
  (or from those and other information either in our possession or likely to come into our possession).</p>
<p class="mb-auto"><b>USAGE DATA</b> is data collected automatically either generated by the use of Service or
  from Service infrastructure itself (for example, the duration of a page visit).</p>
<p class="mb-auto"><b>COOKIES</b> are small files stored on your device (computer or mobile device).</p>
<p class="mb-auto"><b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in
  common with other persons) determines the purposes for which and the manner in which any personal data are, or
  are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
<p class="mb-auto"><b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal person who processes
  the data on behalf of the Data Controller. We may use the services of various Service Providers in order to
  process your data more effectively.</p>
<p class="mb-auto"><b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.</p>
<p class="mb-auto"><b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject,
  who is the subject of Personal Data.</p>
      <h2 class="h3 mb-auto">3. Information Collection and Use</h2>
      <p class="mb-auto">We collect several different types of information for various purposes to provide and improve
        our Service to you.</p>

      <h2 class="h3 mb-auto">4. Types of Data Collected</h2>
      <p class="mb-auto"><b>Personal Data</b></p>
      <p class="mb-auto">While using our Service, we may ask you to provide us with certain personally identifiable
        information that can be used to contact or identify you (“Personal Data”). Personally identifiable information
        may include, but is not limited to:</p>
      <p class="mb-auto">0.1. Email address</p>
      <p class="mb-auto">0.2. Google ID, and API access tokens (stored until the session ends, based on Google policies)</p>
      <p class="mb-auto">We may use your Personal Data to contact you with newsletters, marketing or promotional
        materials and other information that may be of interest to you. You may opt out of receiving any, or all, of
        these communications from us by following the unsubscribe link or by emailing at info@postsheet.com.</p>
      <p class="mb-auto">Your Google data is required to access your spreadsheets and also to send emails from your
        Gmail address, if you choose to.</p>

      <h2 class="h3 mb-auto">4.1. Scopes of Access</h2>
      <p class="mb-auto">We request the following Google OAuth scopes to perform the services in our app:</p>
      <ul class="list-disc indent">
        <li><b>https://www.googleapis.com/auth/spreadsheets.readonly</b>: To read data from Google Sheets.</li>
        <li><b>https://www.googleapis.com/auth/gmail.send</b>: To send emails via the user’s Gmail account.</li>
        <li><b>https://www.googleapis.com/auth/gmail.modify</b>: To read and modify Gmail threads (only related to email campaigns sent via our platform).</li>
      </ul>

      <h2 class="h3 mb-auto">5. Use of Data</h2>
      <p class="mb-auto">La Purisima LLC uses the collected data for various purposes:</p>
      <p class="mb-auto">0.1. to provide and maintain our Service;</p>
      <p class="mb-auto">0.2. to notify you about changes to our Service;</p>
      <p class="mb-auto">0.3. to allow you to participate in interactive features of our Service when you choose to do
        so;</p>
      <p class="mb-auto">0.4. to provide customer support;</p>
      <p class="mb-auto">0.5. to gather analysis or valuable information so that we can improve our Service;</p>
      <p class="mb-auto">0.6. to monitor the usage of our Service;</p>
      <p class="mb-auto">0.7. to detect, prevent and address technical issues;</p>
      <p class="mb-auto">0.8. to fulfil any other purpose for which you provide it;</p>
      <p class="mb-auto">0.9. to carry out our obligations and enforce our rights arising from any contracts entered
        into between you and us, including for billing and collection;</p>
      <p class="mb-auto">0.10. to provide you with notices about your account and/or subscription, including expiration
        and renewal notices, email-instructions, etc.;</p>
      <p class="mb-auto">0.11. to provide you with news, special offers and general information about other goods,
        services and events which we offer that are similar to those that you have already purchased or enquired about
        unless you have opted not to receive such information;</p>
      <p class="mb-auto">0.12. in any other way we may describe when you provide the information;</p>
      <p class="mb-auto">0.13. for any other purpose with your consent.</p>

      <h2 class="h3 mb-auto">6. Gmail Threads and Replies</h2>
      <p class="mb-auto">As part of our service, we access Gmail email threads associated with email campaigns sent through our platform. Specifically:</p>
      <ul class="list-disc indent">
        <li>We only access email threads that are directly related to email campaigns initiated through the app. No unrelated emails or threads are accessed.</li>
        <li>We allow users to reply to those threads through the app.</li>
        <li>We do not modify or delete any emails in the user's inbox. Deletions of threads occur only from our database for records management purposes and do not affect the user's Gmail account.</li>
      </ul>
      <p class="mb-auto">The Gmail data, including the content of email threads and replies, is accessed in real-time and is not stored on our servers. Tokens are retained until the session ends, and the session duration is based on Google's policies. We do not share or disclose Gmail data with any third parties.</p>

      <h2 class="h3 mb-auto">7. Retention of Data</h2>
<p class="mb-auto">We will retain your Personal Data only for as long as is necessary for the purposes set out in
  this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal
  obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
  disputes, and enforce our legal agreements and policies.</p>
<p class="mb-auto">You may delete your personal data at any time by deleting your profile. Once your profile is deleted, all associated personal data, including email addresses, Google ID, and API tokens, will be permanently deleted from our systems. However, some data may be retained if required for legal purposes.</p>
<p class="mb-auto">We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
  for a shorter period, except when this data is used to strengthen the security or to improve the functionality
  of our Service, or we are legally obligated to retain this data for longer time periods.</p>

<h2 class="h3 mb-auto">11. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
<p class="mb-auto">If you are a resident of the European Union (EU) and European Economic Area (EEA), you have
  certain data protection rights, covered by GDPR.</p>
<p class="mb-auto">We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of
  your Personal Data.</p>
<p class="mb-auto">If you wish to be informed what Personal Data we hold about you and if you want it to be
  removed from our systems, please email us at info@postsheet.com.</p>
<p class="mb-auto">You may delete your personal data at any time by deleting your profile within our service. Once your profile is deleted, all personal data associated with it will be permanently removed from our systems, unless retention is required by law.</p>
<p class="mb-auto">In certain circumstances, you have the following data protection rights:</p>
<p class="mb-auto">0.1. the right to access, update or to delete the information we have on you;</p>
<p class="mb-auto">0.2. the right of rectification. You have the right to have your information rectified if that
  information is inaccurate or incomplete;</p>
<p class="mb-auto">0.3. the right to object. You have the right to object to our processing of your Personal Data;
</p>
<p class="mb-auto">0.4. the right of restriction. You have the right to request that we restrict the processing of
  your personal information;</p>
<p class="mb-auto">0.5. the right to data portability. You have the right to be provided with a copy of your
  Personal Data in a structured, machine-readable and commonly used format;</p>
<p class="mb-auto">0.6. the right to withdraw consent. You also have the right to withdraw your consent at any
  time where we rely on your consent to process your personal information;</p>
<p class="mb-auto">Please note that we may ask you to verify your identity before responding to such requests.
  Please note, we may not be able to provide Service without some necessary data.</p>
<p class="mb-auto">You have the right to complain to a Data Protection Authority about our collection and use of
  your Personal Data. For more information, please contact your local data protection authority in the European
  Economic Area (EEA).</p>

      <h2 class="h3 mb-auto">8. Transfer of Data</h2>
      <p class="mb-auto">Your information, including Personal Data, may be transferred to – and maintained on –
        computers located outside of your state, province, country or other governmental jurisdiction where the data
        protection laws may differ from those of your jurisdiction.</p>
      <p class="mb-auto">If you are located outside United States and choose to provide information to us, please note
        that we transfer the data, including Personal Data, to United States and process it there.</p>
      <p class="mb-auto">Your consent to this Privacy Policy followed by your submission of such information represents
        your agreement to that transfer.</p>
      <p class="mb-auto">La Purisima LLC will take all the steps reasonably necessary to ensure that your data is
        treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take
        place to an organisation or a country unless there are adequate controls in place including the security of your
        data and other personal information.</p>
          <h2 class="h3 mb-auto">9. Disclosure of Data</h2>
          <p class="mb-auto">We may disclose personal information that we collect, or you provide:</p>
          <p class="mb-auto">0.1. Business Transaction</p>
          <p class="mb-auto">If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal
            Data may be transferred.</p>
          <p class="mb-auto">0.2. Other cases. We may disclose your information also</p>
          <p class="mb-auto">0.2.1. to our subsidiaries and affiliates;</p>
          <p class="mb-auto">0.2.2. to contractors, service providers, and other third parties we use to support our
            business;</p>
          <p class="mb-auto">0.2.3. to fulfill the purpose for which you provide it;</p>
          <p class="mb-auto">0.2.4. for the purpose of including your company’s logo on our website;</p>
          <p class="mb-auto">0.2.5. for any other purpose disclosed by us when you provide the information;</p>
          <p class="mb-auto">0.2.6. with your consent in any other cases;</p>
          <p class="mb-auto">0.2.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or
            safety of the Company, our customers, or others.</p>
    
          <h2 class="h3 mb-auto">10. Security of Data</h2>
          <p class="mb-auto">The security of your data is important to us but remember that no method of transmission over
            the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable
            means to protect your Personal Data, we cannot guarantee its absolute security.</p>
    
            <h2 class="h3 mb-auto">11. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
<p class="mb-auto">If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR.</p>
<p class="mb-auto">We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
<p class="mb-auto">If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at info@postsheet.com or delete your profile within the service.</p>
<p class="mb-auto">In certain circumstances, you have the following data protection rights:</p>
<p class="mb-auto">0.1. the right to access, update or to delete the information we have on you (including the ability to delete your profile and associated data);</p>
<p class="mb-auto">0.2. the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</p>
<p class="mb-auto">0.3. the right to object. You have the right to object to our processing of your Personal Data;</p>
<p class="mb-auto">0.4. the right of restriction. You have the right to request that we restrict the processing of your personal information;</p>
<p class="mb-auto">0.5. the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</p>
<p class="mb-auto">0.6. the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</p>
<p class="mb-auto">Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not be able to provide Service without some necessary data.</p>
<p class="mb-auto">You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

    
          <h2 class="h3 mb-auto">12. Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</h2>
          <p class="mb-auto">CalOPPA is the first state law in United States to require commercial websites and online
            services to post a privacy policy. The law’s reach stretches well beyond California to require a person or
            company in the United States (and conceivable the world) that operates websites collecting personally
            identifiable information from California consumers to post a conspicuous privacy policy on its website stating
            exactly the information being collected and those individuals with whom it is being shared, and to comply with
            this policy.</p>
          <p class="mb-auto">According to CalOPPA we agree to the following:</p>
          <p class="mb-auto">0.1. users can visit our site anonymously;</p>
          <p class="mb-auto">0.2. our Privacy Policy link includes the word “Privacy”, and can easily be found on the home
            page of our website;</p>
          <p class="mb-auto">0.3. users will be notified of any privacy policy changes on our Privacy Policy Page;</p>
          <p class="mb-auto">0.4. users are able to change their personal information by emailing us at info@postsheet.com.
          </p>
    
          <h2 class="h3 mb-auto">13. Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</h2>
          <p class="mb-auto">If you are a California resident, you are entitled to learn what data we collect about you, ask
            to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain
            requests and ask us:</p>
          <p class="mb-auto"><b>0.1. What personal information we have about you. If you make this request, we will return
              to you:</b></p>
          <p class="mb-auto">0.0.1. The categories of personal information we have collected about you.</p>
          <p class="mb-auto">0.0.2. The categories of sources from which we collect your personal information.</p>
          <p class="mb-auto">0.0.3. The business or commercial purpose for collecting or selling your personal information.
          </p>
          <p class="mb-auto">0.0.4. The categories of third parties with whom we share personal information.</p>
          <p class="mb-auto">0.0.5. The specific pieces of personal information we have collected about you.</p>
          <p class="mb-auto">0.0.6. A list of categories of personal information that we have sold, along with the category
            of any other company we sold it to. If we have not sold your personal information, we will inform you of that
            fact.</p>
          <p class="mb-auto">0.0.7. A list of categories of personal information that we have disclosed for a business
            purpose, along with the category of any other company we shared it with.</p>
          <p class="mb-auto">Please note, you are entitled to ask us to provide you with this information up to two times in
            a rolling twelve-month period. When you make this request, the information provided may be limited to the
            personal information we collected about you in the previous 12 months.</p>
          <p class="mb-auto"><b>0.2. To delete your personal information. If you make this request, we will delete the
              personal information we hold about you as of the date of your request from our records and direct any service
              providers to do the same. In some cases, deletion may be accomplished through de-identification of the
              information. If you choose to delete your personal information, you may not be able to use certain functions
              that require your personal information to operate.</b></p>
          <p class="mb-auto"><b>0.3. To stop selling your personal information. We don’t sell or rent your personal
              information to any third parties for any purpose. We do not sell your personal information for monetary
              consideration. However, under some circumstances, a transfer of personal information to a third party, or
              within our family of companies, without monetary consideration may be considered a “sale” under California
              law. You are the only owner of your Personal Data and can request disclosure or deletion at any time.</b></p>
          <p class="mb-auto">If you submit a request to stop selling your personal information, we will stop making such
            transfers.</p>
          <p class="mb-auto">Please note, if you ask us to delete or stop selling your data, it may impact your experience
            with us, and you may not be able to participate in certain programs or membership services which require the
            usage of your personal information to function. But in no circumstances, we will discriminate against you for
            exercising your rights.</p>
          <p class="mb-auto">To exercise your California data protection rights described above, please send your request(s)
            by email: info@postsheet.com.</p>
          <p class="mb-auto">Your data protection rights, described above, are covered by the CCPA, short for the California
            Consumer Privacy Act. To find out more, visit the official California Legislative Information website. The CCPA
            took effect on 01/01/2020.</p>
    
          <h2 class="h3 mb-auto">14. Service Providers</h2>
          <p class="mb-auto">We may employ third party companies and individuals to facilitate our Service (“Service
            Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our
            Service is used.</p>
          <p class="mb-auto">These third parties have access to your Personal Data only to perform these tasks on our behalf
            and are obligated not to disclose or use it for any other purpose.</p>
    
          <h2 class="h3 mb-auto">15. Analytics</h2>
          <p class="mb-auto">We do not use any third-party Service Providers to monitor and analyze the use of our Service.
          </p>
    
          <h2 class="h3 mb-auto">16. Emails</h2>
          <p class="mb-auto">We do not use any tracking pixels in our emails.</p>
    
          <h2 class="h3 mb-auto">17. Cookies</h2>
          <p class="mb-auto">We use a cryptographically signed cookie named “auth“ to store your login session data.</p>
          <p class="mb-auto">If you visit our website though an affiliate link, we use a cookie named “ref_uid“ to store the
            affiliate's unique user identifier and another cookie named “ref_time“ to store the time of the visit.</p>
    
          <h2 class="h3 mb-auto">18. Payments</h2>
          <p class="mb-auto">We use Stripe for payment, analytics, and other business services. Stripe collects identifying
            information about the devices that connect to its services. Stripe uses this information to operate and improve
            the services it provides to us, including for fraud detection. You can learn more about Stripe and read its
            privacy policy at <a href="https://stripe.com/privacy" class="link">https://stripe.com/privacy</a>.</p>
    
          <h2 class="h3 mb-auto">19. Links to Other Sites</h2>
          <p class="mb-auto">Our Service may contain links to other sites that are not operated by us. If you click a third
            party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy
            of every site you visit.</p>
          <p class="mb-auto">We have no control over and assume no responsibility for the content, privacy policies or
            practices of any third party sites or services.</p>
    
          <h2 class="h3 mb-auto">20. Children’s Privacy</h2>
          <p class="mb-auto">Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).
          </p>
          <p class="mb-auto">We do not knowingly collect personally identifiable information from Children under 18. If you
            become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have
            collected Personal Data from Children without verification of parental consent, we take steps to remove that
            information from our servers.</p>
    
          <h2 class="h3 mb-auto">21. Changes to This Privacy Policy</h2>
          <p class="mb-auto">We may update our Privacy Policy from time to time. We will notify you of any changes by
            posting the new Privacy Policy on this page.</p>
          <p class="mb-auto">You are advised to review this Privacy Policy periodically for any changes. Changes to this
            Privacy Policy are effective when they are posted on this page.</p>
    
          <h2 class="h3 mb-auto">22. Contact Us</h2>
          <p class="mb-auto">If you have any questions about this Privacy Policy, please contact us by email:
            info@postsheet.com.</p>
        </section>
    
      
    
  );
};



export default PrivacyPolicy;
