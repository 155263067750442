import React, { useState } from 'react';
import { Box, Typography, Paper, TextField, Grid, Checkbox, FormControlLabel, Link } from "@material-ui/core";
import { H2 } from '../../../styled-components/Typography';
import { PrimaryButton } from '../../../styled-components/Buttons';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation

const AffiliateForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    country: '',
    website: '',
    newsletter: '',
    youtube: '',
    podcast: '',
    twitter: '',
    instagram: '',
    facebook: '',
    linkedin: '',
    agree: false
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();  // Initialize useNavigate

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = 'Full Name is required';
    if (!formData.email) formErrors.email = 'Email is required';
    else if (!validateEmail(formData.email)) formErrors.email = 'Invalid email address';
    if (!formData.country) formErrors.country = 'Country is required';
    if (!formData.agree) formErrors.agree = 'You must agree to the terms';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheet.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken, 
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch('https://postsheet.com/api/affiliate/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        // Handle successful submission (e.g., show a success message or redirect)
   

        navigate('/affiliate/success');  // Redirect to success page
      } else {
        // Handle errors
        console.error('Error submitting form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      recordError(error.message, 'https://postsheet.com/api/affiliate/signup', formData);
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" padding="20px">
      <Paper style={{ width: "60%", padding: "20px" }}>
        <Box style={{ width: "100%" }}>
          <H2>Affiliate Sign Up</H2>
          <br />
          <Typography>Please fill in this form if you would like to join the PostSheet Affiliate Program.</Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Full Name"
                  name="name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country"
                  name="country"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.country}
                  onChange={handleChange}
                  error={!!errors.country}
                  helperText={errors.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Website"
                  name="website"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.website}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Newsletter"
                  name="newsletter"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.newsletter}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="YouTube"
                  name="youtube"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.youtube}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Podcast"
                  name="podcast"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.podcast}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Twitter"
                  name="twitter"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.twitter}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Instagram"
                  name="instagram"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.instagram}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Facebook"
                  name="facebook"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.facebook}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="LinkedIn"
                  name="linkedin"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={formData.linkedin}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <br />
            <div className="form-field-separator"></div>
            <FormControlLabel
              control={<Checkbox name="agree" checked={formData.agree} onChange={handleChange} required />}
              label={<Typography>I agree to the PostSheet <Link href="/affiliate/terms" className="link">Affiliate Terms</Link>.</Typography>}
            />
            <br />
            <PrimaryButton variant="contained" color="primary" type="submit">
              Submit
            </PrimaryButton>
          </form>
        </Box>
      </Paper>
    </Box>
  );
};

export default AffiliateForm;
