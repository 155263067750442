import React, { useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '../../../components/CKEditor/build/ckeditor';
import { useNewCampaign } from '../../../contexts/NewCampaignContext';

import './editor.css';

const TwilioTextEditor = ({ template }) => { // Destructure template from props
  const { editorRef, activeInputRef, setTwilioTemplateData, twilioTemplateData } = useNewCampaign();

  const onReadyEditor = (editor) => {
    editorRef.current = editor;
    editor.editing.view.document.on('focus', () => {
      activeInputRef.current = editor;
    });

    // Set the initial content of the editor only once
    if (!editorRef.current.hasBeenSet) {
      if(!template) {
        editor.setData(twilioTemplateData.smsTemplate);
      } else {

        editor.setData(template.action_config.body_html);
      }
      
      editorRef.current.hasBeenSet = true;
    }
  };

  const onChangeEditor = (event, editor) => {
    const data = editor.getData();
    setTwilioTemplateData('smsTemplate', data);
  };

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <CKEditor
        editor={Editor}
        onReady={onReadyEditor}
        onChange={onChangeEditor}
        config={{
          height: '300px',
        }}
      />
    </div>
  );
};

export default TwilioTextEditor;