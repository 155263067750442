import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

const SelectedSms = ({ selectedSms }) => {

    useEffect(() => {
  
    }, [selectedSms]);

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">
          From Number: {selectedSms.from_number} 
        </Typography>
        <Typography variant="h6">
          To Number: {selectedSms.to_number} 
        </Typography>

        <Typography sx={{ marginTop: 2 }} dangerouslySetInnerHTML={{ __html: selectedSms.body }} />
      </Box>
    </>
  );
};

export default SelectedSms;
