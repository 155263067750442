import { Box, Paper, Typography, Button } from "@material-ui/core";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const ErrorComponent = () => {
  const [emailSent, setEmailSent] = useState(false);
  const location = useLocation();
  const errorMessage = location.state?.errorMessage || "Unknown error occurred";

  const handleSendEmail = () => {
    const mailtoLink = `mailto:henrique@postsheet.com,support@postsheet.com,joe@postsheet.com?subject=Error Report&body=There was an error: ${encodeURIComponent(
      errorMessage
    )}`;
  
    // Log the mailto link for debugging
    // window.location.href = mailtoLink;
    window.open(mailtoLink, '_blank');
    setEmailSent(true);
  };

  return (
    <Paper style={{ padding: "2rem", maxWidth: "600px", margin: "2rem auto" }}>
      <Typography variant="h6" gutterBottom>
        Oops, it seems there was an error in the request response
      </Typography>
      <Typography variant="body1" paragraph>
        We are making some updates to better serve your needs. Please help us identify and correct any issues by sending us an email with the problem details. We ask that you include your name and what you were trying to do when you encountered the error. Feel free to include images as they can help us a lot as well.
      </Typography>
      <Typography variant="body2" color="error" paragraph>
        {errorMessage}
      </Typography>
      <Box display="flex" justifyContent="center" marginTop="2rem">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendEmail}
          disabled={emailSent}
        >
          {emailSent ? "Email Sent" : "Send Email"}
        </Button>
      </Box>
    </Paper>
  );
};

export default ErrorComponent;
