import { styled } from '@mui/system';

export const AppContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    // Adicione mais estilos globais ou de reset aqui conforme necessário
  }));

  

export  const DashboardContainer = styled('div')(({theme}) => ({
    flexGrow: 1,
    marginTop: '5rem',
    marginBottom: '5rem'
}))

export const Confined = styled('div')(({theme}) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
   
}))

export const Flex = styled('div')(({theme}) => ({
    display: 'flex'
}))