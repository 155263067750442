import React from "react";
import { Box, Typography } from "@mui/material";
// Adicione esta importação se você decidir usar a sanitização

const SelectedEmail = ({ selectedEmail }) => {
 
  const sanitizeBodyHtml = (bodyHtml) => {
    // 1. Remove <br>&nbsp;
    let sanitizedHtml = bodyHtml.replace(/<br>\s*&nbsp;/g, '<br>');
    
    // 2. Replace <p> with <div> for better control over spacing
    sanitizedHtml = sanitizedHtml.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>');
    
    return sanitizedHtml;
  };
  const sanitizedBodyHtml = sanitizeBodyHtml(selectedEmail.body_html);
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h6">
          From: {selectedEmail.from_name} &lt;{selectedEmail.from_address}&gt;
        </Typography>
        <Typography variant="h6">
          To: {selectedEmail.to_name} &lt;{selectedEmail.to_address}&gt;
        </Typography>
        <Typography variant="h6">
          Subject: {selectedEmail.subject}
        </Typography>
        <Typography sx={{ marginTop: 2 }} dangerouslySetInnerHTML={{ __html: sanitizedBodyHtml }} />
      </Box>
    </>
  );
};

export default SelectedEmail;
