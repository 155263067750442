import React, { useState, useRef } from 'react';
import {
  Paper,
  Typography,
  Box,
  List,
  ListItemIcon,
  ListItem,
  ListItemText
} from '@mui/material';
import { useAuth } from '../../../../../contexts/AuthContext';
import { H2, SubHeader, TextSimple } from '../../../../../styled-components/Typography';
import { PrimaryButton } from '../../../../../styled-components/Buttons';

function XLSUploadPanel({ onFileSelected, checkFileExists }) {
  const [fileName, setFileName] = useState("");
  const { isAuthenticated } = useAuth();
  const [warning, setWarning] = useState(true);  // State to track if a file has been selected

  const handleFileSelect = (file) => {
    setFileName(file.name);
    setWarning(false); // Clear warning once a file is selected
    onFileSelected(file);
    checkFileExists(file, onFileSelected);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const fileInputRef = useRef(null);

  if (!isAuthenticated) {
    return <Typography>Você precisa estar autenticado para acessar esta página.</Typography>;
  }

  const steps = [
    "Find your XLS file on your computer.",
    "Make sure it is properly formatted.",
    "The first row of your XLS file MUST be the header of your data.",
    "Click 'Choose File' and select your XLS file.",
    "Click 'Next' to upload your file and choose your action.",
    "Your file is automatically added to your files.",
  ];

  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      <H2>New Campaign</H2>
      <SubHeader>Where is your XLS?</SubHeader>
      <Box sx={{ my: 2 }}>
        <Box>
          <List sx={{ width: '100%' }}>
            {steps.map((text, index) => (
              <ListItem sx={{ py: 0.5, px: 0 }} key={index}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <TextSimple variant="body1">{index + 1}.</TextSimple>
                </ListItemIcon>
                <ListItemText primary={<TextSimple>{text}</TextSimple>} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ mt: 2 }}>
          <PrimaryButton variant="contained" component="label">
            Choose File
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept=".xls,.xlsx"  // Restrict file type to XLS and XLSX
            />
          </PrimaryButton>
          {fileName && (
            <>
              <Typography sx={{ mt: 2 }}>
                Your XLS: <strong>{fileName}</strong>
              </Typography>
              <Box sx={{ mt: 2, p: 2, backgroundColor: '#d4edda', borderRadius: '4px' }}>
                <Typography sx={{ color: '#155724' }}>
                  Click the "Next" button to proceed.
                </Typography>
              </Box>
            </>
          )}
          {!fileName && warning && (
            <Box sx={{ mt: 2, p: 2, backgroundColor: '#fde9e2', borderRadius: '4px' }}>
              <Typography sx={{ color: '#8a3c09' }}>
                Please select a file to proceed. The 'Next' button will be enabled as soon as your spreadsheet has been loaded and validated.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

export default XLSUploadPanel;
