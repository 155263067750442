import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link } from '@mui/material';
import { H2 } from '../../../styled-components/Typography';

// Definição de tipos para as props
interface Column {
  title: string;
  field: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  render?: (data: any) => React.ReactNode;
}

interface ReusableTableProps {
  data: any[];
  columns: Column[];
  noDataText: string;
  title?: string;
  padding?: boolean;
}

const ReusableTable: React.FC<ReusableTableProps> = ({ data, columns, noDataText, title, padding }) => {
  const tableContainerStyle = padding ? { padding: '1.5rem' } : {};
  return (
    <>
    <TableContainer component={Paper} style={tableContainerStyle}>
       {title && <H2>{title}</H2>}
      <Table aria-label="customizable table" >
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={column.align || 'left'}>{column.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <TableCell key={colIndex} align={column.align || 'left'}>
                    {column.render ? column.render(row) : row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                {noDataText}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>



</>
  );
};

export default ReusableTable;
