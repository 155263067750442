import React from 'react';
import { Box, Typography, Paper } from "@material-ui/core";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { H2 } from '../../../styled-components/Typography';
import { PrimaryButton } from '../../../styled-components/Buttons';

const Highlight = styled.span`
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: rgb(252, 87, 47);
  text-decoration-thickness: 2px;  // Adjust the thickness of the underline as needed
`;

const StartEarning = () => {
  const navigate = useNavigate();



  return (
    <Paper style={{ width: "100%", paddingBottom: "20px", paddingTop: "20px", height: "auto" }}>
      <Box style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
        <H2>Affiliate Sign Up</H2>
        <br />
        <br />      

      <p>Thank you!</p>
      <p>We will review your application and contact you via email.</p>
      </Box>
    </Paper>
  );
};

export default StartEarning;
