import React from 'react';
import { Box, Button, Card, CardActionArea, CardContent, Grid, Typography, Paper, TextField, Link } from '@mui/material';
import { FaExternalLinkAlt } from "react-icons/fa";
import { useProfile } from '../../../contexts/ProfileContext';
import { useEffect } from 'react';

function AddPlanManagedAccount() {
    const { managedProfileData,setManagedProfileData, fetchManagedProfileData } = useProfile();
    useEffect(() => {

        const data = fetchManagedProfileData();
        setManagedProfileData(data)
      }, []); 
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Add a plan 
          </Typography>
          <Typography paragraph>
            To continue using PostSheet with your new managed account select a plan and add a payment method:
          </Typography>
          <Box component="form" id="subscription-form" noValidate autoComplete="off">
            <Typography variant="subtitle1" gutterBottom>
              Credit or Debit Card
            </Typography>
            <TextField
              id="card-element"
              label="Card details"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Typography variant="body2" color="textSecondary" gutterBottom>
              If you prefer, you can do it on your stripe portal:
            </Typography>
            <Button variant="text" endIcon={<FaExternalLinkAlt />}>
              Billing Portal
            </Button>
          </Box>
          <Typography variant="h6" gutterBottom>
            Help us to improve!
          </Typography>
          <Typography paragraph>
            If <b>PostSheet</b> did not meet your expectations, or if you didn't deactivate your account, let us know.
          </Typography>
          <Button variant="text">Support Email</Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5" gutterBottom>
              Choose a Plan:
            </Typography>
            {managedProfileData.products_with_prices.map((product) => (
              <Card key={product.id} sx={{ mb: 2 }}>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {product.name}
                    </Typography>
                    {product.prices.map((price) => (
                      <Typography key={price.id} variant="body1">
                        {price.nickname} - {price.unit_amount}
                      </Typography>
                    ))}
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
            <Button variant="contained" color="primary" form="subscription-form">
              Submit Payment
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddPlanManagedAccount;
