import React, { useState, useEffect, useContext } from 'react';
import { Paper, Box, Button, Collapse, Typography, TextField, FormControlLabel, Checkbox, Tooltip,CircularProgress, Select, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useNavigate } from 'react-router-dom';
import { H3 } from '../../../../styled-components/Typography';
import { SecondaryButton } from '../../../../styled-components/Buttons';
import EditorModal from './OptOutMessageEditor/Editor';
import Scheduler from './options_tabs/Scheduler';
import Editor from '../../../../components/CKEditor/build/ckeditor';

import { useNewCampaign } from '../../../../contexts/NewCampaignContext';
import BccInput from './bcc/BccInput';

const Options = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [expanded, setExpanded] = useState('other');
  const [openModal, setOpenModal] = useState(false);
  const {saveAsTemplate, setSaveAsTemplate, stopOnFailedItems, setStopOnFailedItems, trackEmails, trackLinks, setTrackEmails, setTrackLinks} = useNewCampaign()
  const navigate = useNavigate()
  const handleExpandClick = (section) => {
    setExpanded(expanded === section ? null : section);
  };

  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheet.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  useEffect(() => {
    const fetchScheduledJobs = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch('https://postsheet.com/api/jobs/scheduled-jobs', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({})
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setScheduledJobs(data);
      } catch (error) {
        setError(error.message);
        recordError(error.message, 'https://postsheet.com/api/jobs/scheduled-jobs', {});
        navigate('/app/error', { state: { errorMessage: error.message } });
      } finally {
        setLoading(false);
      }
    };

    fetchScheduledJobs();
  }, []);

  if (isLoading) {
    <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <CircularProgress />
    </Box>
  </Paper>
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Paper elevation={1} sx={{ margin: "20px 0" }}>
        <Box display="flex" justifyContent="space-around">
          <Button
            onClick={() => handleExpandClick('schedule')}
            sx={{
              margin: 1,
              color: expanded === 'schedule' ? 'black !important' : 'rgba(0, 0, 0, 0.6)'
            }}
          >
            {"Schedule"}
          </Button>
         
          <Button
            onClick={() => handleExpandClick('other')}
            sx={{
              margin: 1,
              color: expanded === 'other' ? 'black !important' : 'rgba(0, 0, 0, 0.6)'
            }}
          >
            {"Settings"}
          </Button>
        </Box>
        <Collapse in={expanded === 'schedule'}>
          <Scheduler scheduledJobs={scheduledJobs}/>
        </Collapse>
        <Collapse in={expanded === 'other'}>
          <Box sx={{ padding: 3 }}>
            <br/>
            <Tooltip title="Save your campaign as a template for future use">
              <FormControlLabel
                control={<Checkbox checked={saveAsTemplate} onChange={(e) => setSaveAsTemplate(e.target.checked)} />}
                label="Save as Template"
              />
            </Tooltip>
            <Tooltip title="Your campaign will stop if any item fails">
              <FormControlLabel
                control={<Checkbox checked={stopOnFailedItems} onChange={(e) => setStopOnFailedItems(e.target.checked)} />}
                label="Stop on failed items"
              />
            </Tooltip>
            <Tooltip title="It will track the opening of emails">
              <FormControlLabel
                control={<Checkbox checked={trackEmails} onChange={(e) => setTrackEmails(e.target.checked)} />}
                label="Track Emails"
              />
            </Tooltip>
            <Tooltip title="It will track openings to any links that might be in the body of the emails">
              <FormControlLabel
                control={<Checkbox checked={trackLinks} onChange={(e) => setTrackLinks(e.target.checked)} />}
                label="Track Links"
              />
            </Tooltip>
          </Box>
        </Collapse>
      </Paper>
      <EditorModal openModal={openModal} setOpenModal={setOpenModal}/>
    </>
  );
}

export default Options;
