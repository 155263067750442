import React from 'react';
import './index.css';
import '../../../../../stylesheets/styles.css'

const Integrations: React.FC = () => {
  return (
    <section className="integrations-section" style = {{marginBottom: '1.5rem'}}>
      <h2 className="h1">Integrations</h2>
      <h3 className="subtitle">
        Use popular email service providers or your own SMTP server.
      </h3>
      <br />
      <br />
      <br />

      <div className="integrations-container">
        <img
          src="../../static/assets/images/accounts/gmail.svg"
          alt="Google"
          className="integration-brands"
          style={{ width: '150px', height: '75px' }}
        />
          <img
          src="../../static/assets/images/accounts/airtable.svg"
          alt="Google"
          className="integration-brands"
          style={{ width: '150px', height: '75px' }}
        />
           <img
          src="../../static/assets/images/accounts/twilio.svg"
          alt="Google"
          className="integration-brands"
          style={{ width: '150px', height: '75px' }}
        />
       
      </div>
    </section>
  );
};

export default Integrations;
