import React from 'react';
import './index.css';

import '../../../../../stylesheets/styles.css'

interface TestimonialProps {
  text: string;
  name: string;
  avatar: string;
  link: string;
  link_title: string;
  twitter?: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
  text,
  name,
  avatar,
  link,
  link_title,
  twitter,
}) => {
  return (
    <div className="testimonial-card" style={{ width: '280px' }}>
      <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
      <div className="image-wrapper">
        <img
          src={`../../static/assets/images/testimonials/${avatar}`}
          className="avatar"
          style={{ width: '45px', height: '45px' }}
          alt=" "
        />
        <div className="card">
          <span className="testimonial-name">{name}</span>
          <a href={link} target="_blank" rel="noreferrer" className="link">
            {link_title}
          </a>
        </div>
      </div>
    </div>
  );
};

interface TestimonialsProps {
  testimonials: TestimonialProps[];
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
  return (
    <div className="wrapper" style ={{marginBottom: '1.5rem'}}>
      {testimonials.map((testimonial) => (
        <Testimonial
          key={testimonial.name}
          text={testimonial.text}
          name={testimonial.name}
          avatar={testimonial.avatar}
          link={testimonial.link}
          link_title={testimonial.link_title}
        />
      ))}
    </div>
  );
};

export default Testimonials;
