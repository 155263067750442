import React, { useEffect, useState } from 'react';
import { Paper, Box, CircularProgress } from '@mui/material';
import './index.css'; // Ensure this path is correct



const AffiliateProgram = () => {
  



  return (
    <div>
      <br/>
          <br/>
          <br/>
      <section className="confined">
      <h1 className="h1" style={{ textAlign: 'left' }}>Affiliate Program</h1>
        <br/>
        <br/>
        <div class="flex md:flex-col">
        <div>
          <p>Join the PostSheet Affiliate Program and start earning <span
              class="font-bold border-b-2 border-primary">25%</span> recurring commission on every sale!</p>
          <br /><br /><br />

          <h3 class="h3">How does it work?</h3>
          <ol class="mt-4 list-decimal indent">
            <li>Sign up and get your sharing link.</li>
            <li>Promote PostSheet on your website and social media.</li>
            <li>Get paid <b>25%</b> of every sale — every month.</li>
          </ol>
          <br /><br />

          <a href="/affiliate/signup" class="button-primary">Join Now</a>
        </div>

        <div class="w-16 h-16"></div>

        <div class="max-w-xl mx-auto">
          <img src="../../static/assets/images/affiliate-dashboard.png" class="rounded-md shadow-big" />
        </div>
      </div>
      </section>

      <br />
      <br />
      <br />
      <br />
      <br />

      <section className="confined panel">
        <h2 className="h1">FAQ</h2>
        <br />
        <br />

        <div>
          <p className="mb-1 font-bold">How much money will I earn?</p>
          <p className="mb-auto text-gray-500">
          You will get 25% recurring commission on every sale. You will be paid as long as your referred customer stays with us.
          </p>
        </div>

        <div>
          <p className="mb-1 font-bold">When will I get paid?</p>
          <p className="mb-auto text-gray-500">We pay all of your earned commission at the beginning of each month.</p>
        </div>

        <div>
          <p className="mb-1 font-bold">What is the minimum payout amount?</p>
          <p className="text-gray-500">
          The minimum payout amount is $25.
          </p>
        </div>
        <div>
          <p className="mb-1 font-bold">Are there any limits on how much I can earn?</p>
          <p className="text-gray-500">
          No, there are no commission limits.
          </p>
        </div>
        <div>
          <p className="mb-1 font-bold">How do you track my sales?</p>
          <p className="text-gray-500">
          We use cookies to track and record sales made through affiliate links. The tracking cookie is valid for 30 days.
          </p>
        </div>
        <div>
          <p className="mb-1 font-bold">Can I see how my campaigns are performing?</p>
          <p className="text-gray-500">
          Yes, you will have access to your own dashboard to view your stats.
          </p>
        </div>
        <div>
          <p className="mb-1 font-bold">I have another question.</p>
          <p className="text-gray-500">
          Please contact us at info@postsheet.com.
          </p>
        </div>
      </section>
      <footer>{/* Include your footer component here */}</footer>
    </div>
  );
};

export default AffiliateProgram;
