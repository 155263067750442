import React from 'react';
import { Box, Typography, Paper } from "@material-ui/core";
import styled from 'styled-components';

import { H2 } from '../../../styled-components/Typography';
import { PrimaryButton } from '../../../styled-components/Buttons';
import './index.css';

const Contact = () => {
  return (
    <Paper style={{ width: "100%", paddingBottom: "20px", paddingTop: "20px", height: "auto", maxHeight: "250px" }}>
      <Box style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
        <H2>Contact</H2>
        <br /><br />

        <Typography variant="body1">
          Are you missing a feature? Is there a bug?
        </Typography>
        <Typography variant="body1">
          Please use this form or send an email to <a href="mailto:support@postsheet.com" className="link"><span className="no-underline">support@postsheet.com</span></a> and let us know.
        </Typography>
        <br />
      </Box>
    </Paper>
  );
};

export default Contact;
