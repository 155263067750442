import React from 'react';
import { ReactComponent as CheckmarkIcon } from './checkmark.svg';
import './index.css'
const Checkmark = () => {
  return (
    <CheckmarkIcon
      className="w-5 h-5 mr-2 fill-current text-primary"
      style={{ color: 'rgb(252 87 47)' }}
    />
  );
};

export default Checkmark;
