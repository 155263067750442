import React, { useEffect, useState } from 'react';
import { Paper, Box, CircularProgress, Button } from '@mui/material';
import './index.css'; // Ensure this path is correct


const SendSmsUsingGSheets = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <>
      <header>
        {/* Include your header content here */}
      </header>

      <section className="confined">
        <h1 className="h1">Send SMS using Google Sheets</h1>
        <br />

        <article>
          <div className="flex lg:flex-col justify-between">
            <div>
              <p className="font-semibold text-gray-800">Learn how to use PostSheet + Google Sheets to send personalized text messages.</p>
              <p className="mt-2 flex items-center text-gray-400">
                <img src="/path/to/time-line.svg" alt="" className="w-4 h-4 mr-1 fill-current" />
                <span className="font-semibold text-tiny">10 min</span>
              </p>
            </div>

          
              <section className="panel -mt-16 lg:mt-10 mb-0">
                <p className="h1">Get started for free</p>

                <div className="flex flex-col items-start mt-4">
                  <span>Subscribe to our Growth Plan 14-day-trial and see how it works!</span>

                  <div className="w-4 h-4"></div>
                  <Button href="/login" variant="contained" color="primary" data-analytics='"Login"'>Get Started</Button>
                </div>
              </section>
  
          </div>
          <br /><br />

          <hr />
          <br /><br />

          <div className="flex flex-row-reverse lg:flex-col">
            <div className="ml-16 lg:ml-0 lg:mb-16">
              <div className="sticky top-0 pt-5">
                <h3 className="h3">Steps</h3>
                <br />
                <ul className="ml-10 list-decimal whitespace-nowrap">
                  <li><a href="#step1" className="link-simple">Prepare your spreadsheet</a></li>
                  <li><a href="#step2" className="link-simple">Sign in</a></li>
                  <li><a href="#step3" className="link-simple">Connect Twilio</a></li>
                  <li><a href="#step4" className="link-simple">Create a new Campaign</a></li>
                  <li><a href="#step5" className="link-simple">Select your spreadsheet</a></li>
                  <li><a href="#step6" className="link-simple">Specify the action</a></li>
                  <li><a href="#step7" className="link-simple">Compose your message</a></li>
                  <li><a href="#step8" className="link-simple">Preview</a></li>
                  <li><a href="#step9" className="link-simple">Send</a></li>
                </ul>
              </div>
            </div>
            <div>
              <StepSection
                stepNumber="1"
                title="Prepare your spreadsheet"
                content={<>
                  <p>PostSheet expects all spreadsheets to have a header.</p>
                  <p>If your Google spreadsheet doesn't have one already, create a new row at the top and fill it with the names of your columns. It doesn't matter if the header row has a different style or not.</p>
                  <a href="https://docs.google.com/spreadsheets/d/1HSG5tWMyjg_psicQ3rBphWmF56TFwulWG_O_tCa4y-8/edit#gid=286059456" target="_blank" className="link inline-flex items-center">See a sample spreadsheet <img src="/path/to/external-link-line.svg" alt="" className="icon-right w-4 h-4 fill-current" /></a>
                  <br /><br />
                  <div className="mt-4">
                    <img src="../../static/content/guides/google-sheets-header.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="2"
                title="Sign in"
                content={<>
                  <p>To use PostSheet, you must first sign in using your Google account.</p>
                  <p>Click on the <a href="/login" data-analytics='"Login"' target="_blank" className="link">Sign In</a> link at the top right corner of the screen and you'll be redirected to Google for authentication.</p>
                  <p>Select the Google account that you want to use and give PostSheet permission to view your spreadsheets and optionally to send email on your behalf.</p>
                  <div className="mt-4">
                    <video id="video-sign-in-google" autoplay loop playsInline muted className="shadow-semi-big rounded-lg overflow-hidden">
                      <source src="../../static/content/guides/sign-in-google.mp4" type="video/mp4" />
                    </video>
                    <div id="video-sign-in-google-progress" className="w-0 mt-4 h-1 bg-gray-200 transition-all duration-1000"></div>
                  </div>
                </>}
              />
              <StepSection
                stepNumber="3"
                title="Connect Twilio"
                content={<>
                  <p>PostSheet does not send the text messages itself. It creates the personalized messages and then hands them over to an SMS service provider to send. Currently only <a href="https://twilio.com" target="_blank" className="link">Twilio</a> is supported but more integrations are coming soon.</p>
                  <p>To use Twilio, first go to <a href="https://twilio.com" target="_blank" className="link">twilio.com</a>, create an account and get your phone number.</p>
                  <img src="../../static/content/guides/twilio-dashboard.png" className="my-4 shadow-semi-big rounded-lg overflow-hidden" />
                  <br /><br />
                  <p>Next, go to <a href="/app/accounts" target="_blank" className="link">My Accounts</a> and click on the Twilio button under the Add an Account section. Then, copy and paste your <b>Account Sid</b> and <b>Auth Token</b> from your Twilio Dashboard and click on the Add button to connect your Twilio account.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/add-account-twilio.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="4"
                title="Create a new Campaign"
                content={<>
                  <p>A Campaign is the basic unit of work in PostSheet. It specifies where to read the data from (Google Sheets or Airtable) and what action to perform on each row (Send Email, Send SMS, etc.).</p>
                  <p>Click on the <b>New Campaign</b> link on the left menu to create a Campaign.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="5"
                title="Select your spreadsheet"
                content={<>
                  <p>Select <b>Google Sheets</b> as your data source.</p>
                  <p>In the next screen, open your Google spreadsheet and select the sheet that you want to use. Then, right click on the location bar, copy the address and paste it in the input box.</p>
                  <div className="mt-4">
                    <video id="video-new-job-data-source-gsheets" autoplay loop playsInline muted className="shadow-semi-big rounded-lg overflow-hidden">
                      <source src="../../static/content/guides/new-job-data-source-gsheets.mp4" type="video/mp4" />
                    </video>
                    <div id="video-new-job-data-source-gsheets-progress" className="w-0 mt-4 h-1 bg-gray-200 transition-all duration-1000"></div>
                  </div>
                </>}
              />
              <StepSection
                stepNumber="6"
                title="Specify the action"
                content={<>
                  <p>Select <b>Send SMS</b> with the service provider account that you want to use to go to the next step.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job-action-send-sms.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="7"
                title="Compose your message"
                content={<>
                  <p>Now you must write your message and specify how it must be customized for each row.</p>
                  <p>For the <b>To Number</b> field, select the column in your spreadsheet that contains the mobile numbers of the recipients.</p>
                  <p>When filling in the other fields, you can use the buttons at the top of the screen to insert placeholders like <b>{`{{ Name }}`}</b> into your text. These placeholders correspond to the columns from your spreadsheet and will be replaced with the actual values from each row.</p>
                  <p>For example, <b className="whitespace-pre">{`{{ Name }}`}</b> will be replaced with the contact's name and <b className="whitespace-pre">{`{{ Address }}`}</b> will be replaced with their address.</p>
                  <div className="mt-4">
                    <video id="video-new-job-action-configure-send-sms" autoplay loop playsInline muted className="shadow-semi-big rounded-lg overflow-hidden">
                      <source src="../../static/content/guides/new-job-action-configure-send-sms.mp4" type="video/mp4" />
                    </video>
                    <div id="video-new-job-action-configure-send-sms-progress" className="w-0 mt-4 h-1 bg-gray-200 transition-all duration-1000"></div>
                  </div>
                </>}
              />
              <StepSection
                stepNumber="8"
                title="Preview"
                content={<>
                  <p>PostSheet will show you a preview of the final message using the first three rows of your spreadsheet.</p>
                  <p>Make sure everything looks good.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job-preview-send-sms.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="9"
                title="Send"
                content={<>
                  <p>Click on the <b>Send</b> button to start sending.</p>
                  <p>PostSheet will show you the progress and you can click on the total number of items to see more details.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job-progress.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <div className="mb-20">
                <h2 className="h2 mb-auto pt-5">Need help?</h2>
                <p>If you need help or have any questions, please contact via <a href="mailto:info@postsheet.com" className="link">info@postsheet.com</a>.</p>
              </div>
            </div>
          </div>
        </article>
      </section>

      <footer>
        {/* Include your footer content here */}
      </footer>

      <script>
        {`
        var video_elements = document.getElementsByTagName('video');

        for (var i = 0; i < video_elements.length; i++) {
          const video_element = video_elements[i];
          const video_progress_element = document.getElementById(video_element.id + '-progress');

          video_element.addEventListener('timeupdate', function() {
            var percent = video_element.currentTime / video_element.duration * 100;
            video_progress_element.style.width = percent + '%';
            video_progress_element.style.transition = (percent < video_progress_element.percent ? 'none' : '');
            video_progress_element.percent = percent;
          });
        }
        `}
      </script>
    </>
  );
};

const StepSection = ({ stepNumber, title, content }) => (
  <div className="mb-20">
    <h2 className="h2 mb-auto pt-5">
      <a name={`step${stepNumber}`} className="pt-5">
        Step {stepNumber}: {title}
      </a>
    </h2>
    {content}
  </div>
);

export default SendSmsUsingGSheets;
