import { styled } from '@mui/system';
import { NavLink as RouterLink } from 'react-router-dom';
import { primaryColor } from './StyleSettings';

export const H2 = styled('h2')(({ theme }) => ({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
    fontWeight: 800,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    margin: 0
    // Adicione mais estilos globais ou de reset aqui conforme necessário
  }));
  export const H3 = styled('h3')(({ theme }) => ({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
    fontWeight: 800,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    margin: 0
    // Adicione mais estilos globais ou de reset aqui conforme necessário
  }));

  export const SubHeader = styled('h3')(({theme}) => ({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    marginTop: '0.25rem'
  }))

  export const DataSourceTitle = styled('p')(({theme}) => ({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
    fontWeight: 700,
    marginTop: '1rem',
    textAlign: 'center', // Alinha o texto ao centro
  marginTop: '8px', 
  }))
  export const H3TableHeader = styled('h3')(({ theme }) => ({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
    fontWeight: 800,
    fontSize: '1.25rem',
    lineHeight: '2.75rem',
    margin: '1rem'

    // Adicione mais estilos globais ou de reset aqui conforme necessário
  }));

  export const LinkSimple = styled(RouterLink)(({ theme }) => ({
  fontFamily: [
    "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
    "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
  ].join(','),
  fontWeight: 700,
  textDecoration: 'none', // Remove underline by default, or customize as needed
  color: 'inherit', // Ensures the link color inherits from its parent
}));

  export const TextSimple = styled('p')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
  }))

  export const TextSimpleMargin = styled('p')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      marginLeft: '1rem'
  }))
  export const BoldUnderlined = styled('span')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      fontWeight: 800,
      textDecoration: 'underline'
  }))

  export const SemiBoldUnderlined = styled('span')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      fontWeight: 700,
      textDecoration: 'underline'
  }))
  export const SemiBold = styled('span')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      fontWeight: 700,
  }))
  export const Bold = styled('span')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      fontWeight: 800,
  }))


  export const PrimaryTextSimple = styled('p')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      color: primaryColor
  }))
  export const PrimaryBoldUnderlined = styled('span')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      fontWeight: 800,
      textDecoration: 'underline',
      color: primaryColor
  }))

  export const PrimarySemiBoldUnderlined = styled('span')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      fontWeight: 700,
      textDecoration: 'underline',
      color: primaryColor
  }))
  export const PrimarySemiBold = styled('span')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      fontWeight: 700,
      color: primaryColor
  }))
  export const PrimaryBold = styled('span')(({theme}) =>({
    fontFamily: [
        "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont",
        "'Segoe UI'", "Roboto", "'Helvetica Neue'", "Arial", "'Noto Sans'", "sans-serif",
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'     
      ].join(','),
      fontWeight: 800,
      color: primaryColor
  }))