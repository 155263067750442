import React, { useEffect, useState } from 'react';
import { Paper, Box, CircularProgress } from '@mui/material';
import './index.css'; // Ensure this path is correct

const SendEmailsUsingAirtable = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <div>
      <header>{/* Include your header component here */}</header>

      <section className="confined">
        <h1 className="h1">Send email using Airtable</h1>
        <br />

        <article>
          <div className="flex lg:flex-col justify-between">
            <div>
              <p className="font-semibold text-gray-800">
                Learn how to use PostSheet + Airtable to send personalized emails. <br />
                This allows you to effectively mail merge using Airtable.
              </p>
              <p className="mt-2 flex items-center text-gray-400">
                <img src="/path/to/time-line.svg" alt="" className="w-4 h-4 mr-1 fill-current" />
                <span className="font-semibold text-tiny">10 min</span>
              </p>
            </div>

            <section className="panel -mt-16 lg:mt-10 mb-0">
              <p className="h1">Get started for free</p>

              <div className="flex flex-col items-start mt-4">
                <span>Subscribe to our Growth Plan 14-day-trial and see how it works!</span>
                <div className="w-4 h-4"></div>
                <a href="/login" data-analytics='"Login"' className="button-primary">Get Started</a>
              </div>
            </section>
          </div>
          <br /><br />

          <hr />
          <br /><br />

          <div className="flex flex-row-reverse lg:flex-col">
            <div className="ml-16 lg:ml-0 lg:mb-16">
              <div className="sticky top-0 pt-5">
                <h3 className="h3">Steps</h3>
                <br />
                <ul className="ml-10 list-decimal whitespace-nowrap">
                  <li><a href="#step1" className="link-simple">Sign in</a></li>
                  <li><a href="#step2" className="link-simple">Connect a service provider</a></li>
                  <li><a href="#step3" className="link-simple">Create a new Campaign</a></li>
                  <li><a href="#step4" className="link-simple">Select your spreadsheet</a></li>
                  <li><a href="#step5" className="link-simple">Specify the action</a></li>
                  <li><a href="#step6" className="link-simple">Compose your message</a></li>
                  <li><a href="#step7" className="link-simple">Preview</a></li>
                  <li><a href="#step8" className="link-simple">Send</a></li>
                </ul>
              </div>
            </div>
            <div>
              <StepSection
                stepNumber="1"
                title="Sign in"
                content={<>
                  <p>To use PostSheet, you must first sign in using your Google account.</p>
                  <p>Click on the <a href="/login" data-analytics='"Login"' target="_blank" className="link">Sign In</a> link at the top right corner of the screen and you'll be redirected to Google for authentication.</p>
                  <p>Select the Google account that you want to use and optionally give PostSheet permission to view your spreadsheets and to send email on your behalf.</p>
                  <div className="mt-4">
                    <video id="video-sign-in-google" autoplay loop playsInline muted className="shadow-semi-big rounded-lg overflow-hidden">
                      <source src="../../static/content/guides/sign-in-google.mp4" type="video/mp4" />
                    </video>
                    <div id="video-sign-in-google-progress" className="w-0 mt-4 h-1 bg-gray-200 transition-all duration-1000"></div>
                  </div>
                </>}
              />
              <StepSection
                stepNumber="2"
                title="Connect a service provider"
                content={<>
                  <p>PostSheet does not send the emails itself. It creates the personalized messages and then hands them over to an email service provider to send.</p>
                  <p>Many popular email service providers are supported including Gmail, Outlook, Mailgun, Mandrill, and Amazon SES. You can also use your own SMTP server.</p>
                  <p>If you want to use Gmail (and have already given PostSheet permission to use it in the previous step), you're good to go. Otherwise, go to <a href="/app/accounts" target="_blank" className="link">My Accounts</a> and add your service provider's account.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/my-accounts.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="3"
                title="Create a new Campaign"
                content={<>
                  <p>A Campaign is the basic unit of work in PostSheet. It specifies where to read the data from (Google Sheets or Airtable) and what action to perform on each row (Send Email, Send SMS, etc.).</p>
                  <p>Click on the <b>New Campaign</b> link on the left menu to create a Campaign.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="4"
                title="Select your spreadsheet"
                content={<>
                  <p>Select <b>Airtable</b> as your data source.</p>
                  <p>In the next screen, select your base and your table.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job-data-source-airtable.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="5"
                title="Specify the action"
                content={<>
                  <p>Select <b>Send Email</b> with the service provider account that you want to use to go to the next step.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job-action-send-email.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="6"
                title="Compose your message"
                content={<>
                  <p>Now you must write your message and specify how it must be customized for each row.</p>
                  <p>For the <b>To Address</b> field, select the column in your spreadsheet that contains the email addresses of the recipients.</p>
                  <p>When filling in the other fields, you can use the buttons at the top of the screen to insert placeholders like <b>{`{{ Name }}`}</b> into your text. These placeholders correspond to the columns from your spreadsheet and will be replaced with the actual values from each row.</p>
                  <p>For example, <b className="whitespace-pre">{`{{ Name }}`}</b> will be replaced with the contact's name and <b className="whitespace-pre">{`{{ Address }}`}</b> will be replaced with their address.</p>
                  <div className="mt-4">
                    <video id="video-new-job-action-configure-send-email" autoplay loop playsInline muted className="shadow-semi-big rounded-lg overflow-hidden">
                      <source src="../../static/content/guides/new-job-action-configure-send-email.mp4" type="video/mp4" />
                    </video>
                    <div id="video-new-job-action-configure-send-email-progress" className="w-0 mt-4 h-1 bg-gray-200 transition-all duration-1000"></div>
                  </div>
                </>}
              />
              <StepSection
                stepNumber="7"
                title="Preview"
                content={<>
                  <p>PostSheet will show you a preview of the final message using the first three rows of your spreadsheet.</p>
                  <p>Make sure everything looks good.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job-preview-send-email.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <StepSection
                stepNumber="8"
                title="Send"
                content={<>
                  <p>Click on the <b>Send</b> button to start sending.</p>
                  <p>PostSheet will show you the progress and you can click on the total number of items to see more details.</p>
                  <div className="mt-4">
                    <img src="../../static/content/guides/new-job-progress.png" className="shadow-semi-big rounded-lg overflow-hidden" />
                  </div>
                </>}
              />
              <div className="mb-20">
                <h2 className="h2 mb-auto pt-5">Need help?</h2>
                <p>If you need help or have any questions, please contact via <a href="mailto:info@postsheet.com" className="link">info@postsheet.com</a>.</p>
              </div>
            </div>
          </div>
        </article>
      </section>

      <footer>{/* Include your footer component here */}</footer>

      <script>
        {`
        var video_elements = document.getElementsByTagName('video');

        for (var i = 0; i < video_elements.length; i++) {
          const video_element = video_elements[i];
          const video_progress_element = document.getElementById(video_element.id + '-progress');

          video_element.addEventListener('timeupdate', function() {
            var percent = video_element.currentTime / video_element.duration * 100;
            video_progress_element.style.width = percent + '%';
            video_progress_element.style.transition = (percent < video_progress_element.percent ? 'none' : '');
            video_progress_element.percent = percent;
          });
        }
        `}
      </script>
    </div>
  );
};

const StepSection = ({ stepNumber, title, content }) => (
  <div className="mb-20">
    <h2 className="h2 mb-auto pt-5">
      <a name={`step${stepNumber}`} className="pt-5">
        Step {stepNumber}: {title}
      </a>
    </h2>
    {content}
  </div>
);

export default SendEmailsUsingAirtable;
