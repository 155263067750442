import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import SelectedSms from './SelectedSms';
import { primaryColor } from '../../../../styled-components/StyleSettings';

const SmsPreview = ({ smss }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Gera nomes de abas com base no número de e-mails, mas não mais do que três
    const smsTabs = Array.from({ length: Math.min(smss.length, 3) }, (_, i) => `Preview ${i + 1}`);

    const selectedSms = smss[selectedTab]; // Determina o e-mail a ser exibido com base na aba selecionada

    useEffect(() => {

    }, [smss, selectedTab, smsTabs, selectedSms]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: primaryColor, // Sua cor personalizada para o indicador
                        }
                    }}
                    sx={{
                        '.MuiTab-root': { color: 'rgba(0, 0, 0, 0.6)' }, // cor padrão para todas as abas não selecionadas
                        '.Mui-selected': { color: 'black !important' } // força a cor preta na aba selecionada
                    }}
                >
                    {smsTabs.map((tab, index) => (
                        <Tab key={index} label={tab} />
                    ))}
                </Tabs>
            </Box>
            <SelectedSms selectedSms={selectedSms} />
        </>
    );
}

export default SmsPreview;
