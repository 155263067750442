import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '../../../../../components/CKEditor/build/ckeditor';
import { useNewCampaign } from '../../../../../contexts/NewCampaignContext';
import './editor.css';

const TextEditor = () => {
  const { editorRef, activeInputRef, emailData } = useNewCampaign();

  const onReadyEditor = (editor) => {
    editorRef.current = editor;
    editor.editing.view.document.on('focus', () => {
      activeInputRef.current = editor;  
    });
  };

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <CKEditor
        editor={Editor}
        data={emailData.emailTemplate} // Set the initial content of the editor
        onReady={onReadyEditor}
        config={{
          height: '300px',
        }}     
      />
    </div>
  );
};

export default TextEditor;
