
import React, { useEffect, useState } from 'react';
import { Paper, Box, CircularProgress } from '@mui/material';
import './index.css'; // Ensure this path is correct

const Guides = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <div>
      <header>{/* Include your header component here */}</header>

      <section className="confined">
      <h1 class="h1">Guides</h1>
<p class="mt-2">Learn how to use PostSheet with these step-by-step guides.</p>
<br /><br /><br />

<div class="mb-24">
  <div class="flex items-end">
    <img src="../../static/assets/images/data-sources/google.gsheets-icon.svg" class="w-12 mr-4" />
    <h2 class="h2">Google Sheets</h2>
  </div>
  <br /><br />

  <div class="grid grid-cols-2 gap-8 md:grid-cols-1">
    <a href="/guides/sendemailsgsheets" class="inline-flex flex-col p-6 rounded-lg border-2 border-gray-125 hover:border-primary">
      <p class="font-bold">Send email using Google Sheets</p>
      <p class="mt-2 font-semibold text-gray-400">Learn how to use PostSheet + Google Sheets to send personalized emails.</p>
    </a>

    <a href="/guides/sendsmsgsheets" class="inline-flex flex-col p-6 rounded-lg border-2 border-gray-125 hover:border-primary">
      <p class="font-bold">Send SMS using Google Sheets</p>
      <p class="mt-2 font-semibold text-gray-400">Learn how to use PostSheet + Google Sheets to send personalized text messages.</p>
    </a>
  </div>
</div>

<div class="mb-24">
  <div class="flex items-end">
    <img src="../../static/assets/images/data-sources/airtable.svg" class="w-12 mr-4" />
    <h2 class="h2">Airtable</h2>
  </div>
  <br /><br />

  <div class="grid grid-cols-2 gap-8 md:grid-cols-1">
    <a href="/guides/sendemailsairtable" class="inline-flex flex-col p-6 rounded-lg border-2 border-gray-125 hover:border-primary">
      <p class="font-bold">Send email using Airtable</p>
      <p class="mt-2 font-semibold text-gray-400">Learn how to use PostSheet + Airtable to send personalized emails.</p>
    </a>

    <a href="/guides/sendsmsairtable" class="inline-flex flex-col p-6 rounded-lg border-2 border-gray-125 hover:border-primary">
      <p class="font-bold">Send SMS using Airtable</p>
      <p class="mt-2 font-semibold text-gray-400">Learn how to use PostSheet + Airtable to send personalized text messages.</p>
    </a>
  </div>
</div>

      </section>

    </div>
  );
};



export default Guides;
