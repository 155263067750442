import React from "react";
import "../../../stylesheets/content.css"
import "../../../stylesheets/index.css"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../stylesheets/styles.css"
import FirstSection from "./home_components/first-section/first-section";
import HowTo from "./home_components/how-it-works/how-it-works";
import TrustedBy from "./home_components/trustedby/trustedby";
import Integrations from "./home_components/integrations/integrations";
import Testimonials from "./home_components/testimonials/testimonials";
import testimonials from "../../../testimonials.json";
import LineIcon from "./home_components/svg-line/divider";

const Home: React.FC = () => {
  const spacingStyle = {
    marginBottom: '2rem', // Adjust the spacing as needed
  };
  const navigate = useNavigate()
  const [isYearly, setIsYearly] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null); // Estado para o id
  const [uid, setUid] = useState(null);
   
  
  const verifyToken = async () => {

    const url = 'https://postsheet.com/api/check-auth';
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          
        },
      });

      if (!response.ok) {
        const errorData = await response.json(); // Tente ler a resposta JSON
        throw new Error(`Falha na autenticação: ${errorData.detail || 'Erro desconhecido'}`);
      }

      const data = await response.json();


      setIsAuthenticated(data.isAuthenticated);
      setId(data.encrypted_id); // Atualiza o id com o valor descriptografado recebido
      setUid(data.encrypted_uid);

    } catch (error: any) {
      console.error('Erro ao verificar a autenticação:', error.message);
      setIsAuthenticated(false);
      
    }
  };


 

  useEffect(() => {
    verifyToken().finally(() => setLoading(false));
  }, []);
  return (
    <>
      <div className="page-container">
        <div style={{ display: "flex", flexDirection: "column", marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={spacingStyle}>
            <FirstSection />
          </div>
          <LineIcon/>
          <div style={spacingStyle}>
            <HowTo />
          </div>
          <LineIcon/>
          <div style={spacingStyle}>
            <TrustedBy />
          </div>
          <LineIcon/>
          <div style={spacingStyle}>
            <Integrations />
          </div>
          <LineIcon/>
          <div style={spacingStyle}>
            <Testimonials testimonials={testimonials} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
