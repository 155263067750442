import React, { useEffect } from 'react';
import { Paper, Box, Typography, Card, CardActionArea, CardMedia, TextField, Button } from '@mui/material';
import { H3, LinkSimple, SubHeader } from "../../../styled-components/Typography";
import { ImArrowRight2 } from "react-icons/im";
import { PrimaryButton } from '../../../styled-components/Buttons';

const cardStyle = {
  width: '280px', // Set the desired width
  height: '145px', // Set the desired height
  padding: '2rem',
  margin: '0 20px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const imgStyle = {
  width: '80px', // Fixed width for the icon
  height: '80px', // Fixed height for the icon
  objectFit: 'contain', // Ensure the image is fully contained within the given dimensions
  margin: 'auto',
};

const dataSourceTypes = [
  {
      "id": 1,
      "key": "google",
      "title": "Google Sheets",
      "data_source_key": "google.gsheets"
  },
  {
      "id": 2,
      "key": "airtable",
      "title": "Airtable",
      "data_source_key": "airtable.airtable"
  },
  {
      "id": 3,
      "key": "xls",
      "title": "Xls",
      "data_source_key": "xls"
  },
  {
      "id": 4,
      "key": "csv",
      "title": "CSV",
      "data_source_key": "csv"
  }
];

const TemplateDetailsTab = ({ template, dataSourceAccount, actionAccount, dataSourceChoices }) => {
 
  const dataSourceType = dataSourceTypes.find(type => type.data_source_key === template.data_source_type) || {};

  useEffect(() => {

  }, [template, dataSourceType]);

  return (
    <>
      <Paper elevation={3} sx={{ p: 4, m: 2 }}>
        <H3>Details</H3>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Card style={cardStyle}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt={dataSourceType.title}
                style={imgStyle}
                image={`../../static/assets/images/data-sources/${dataSourceType.key}.svg`}
              />
              <Typography sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
                {dataSourceType.title}
              </Typography>
              {dataSourceAccount ? (
                <LinkSimple style={{ color: 'grey', display: 'block', marginTop: '1rem' }}>
                  {dataSourceAccount.name}
                </LinkSimple>
              ) : (
                <Typography sx={{ color: 'grey', display: 'block', marginTop: '1rem' }}>
              
                </Typography>
              )}
            </CardActionArea>
          </Card>
          <ImArrowRight2 size={24} style={{ margin: '0 20px' }} />
          {actionAccount && (
            <Card style={cardStyle}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Action"
                  style={imgStyle}
                  image={`../../static/assets/images/accounts/${actionAccount.type}.svg`}
                />
                <Typography sx={{ marginTop: '1rem', fontWeight: 'bold' }}>
                  {actionAccount.type === 'gmail' ? 'Email Action' : 'SMS Action'}
                </Typography>
                <LinkSimple style={{ color: 'grey', display: 'block', marginTop: '1rem', marginBottom: '0.5rem' }}>
                  {actionAccount.name}
                </LinkSimple>
              </CardActionArea>
            </Card>
          )}
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ p: 4, m: 2 }}>
        <H3>Template</H3>
        <Box sx={{ padding: 2 }}>
          {template.data_source_type === 'google.gsheets' && (
            <TextField
              fullWidth
              variant="outlined"
              label="Google Sheets URL"
              value={template.dataSourceConfig}
              disabled
              sx={{ mt: 2 }}
            />
          )}
          {(template.data_source_type === 'xls' || template.data_source_type === 'csv') && (
            <Box sx={{ mt: 2 }}>
              <PrimaryButton variant="contained" >
                File: {template.data_source_config.name}
              </PrimaryButton>
            </Box>
          )}
          {template.data_source_type === 'airtable.airtable' && (
            <Box sx={{ mt: 2 }}>
              <SubHeader>Airtable Configuration</SubHeader>
              <TextField
                fullWidth
                variant="outlined"
                label="Base"
                value={template.data_source_config}
                disabled
                sx={{ mt: 2 }}
              />
              {/* Add similar TextFields for Table and View if needed */}
            </Box>
          )}

          {/* Conditional rendering based on action type */}
          {template.action_type === 'gmail.send-email' ? (
            <>
              <Typography variant="h6" sx={{ mt: 4 }}>
                From: {template.action_config.from_name} &lt;{template.action_config.from_address}&gt;
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                To: {template.action_config.to_name} &lt;{template.action_config.to_address}&gt;
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Subject: {template.action_config.subject}
              </Typography>
              <Typography sx={{ marginTop: 2 }} dangerouslySetInnerHTML={{ __html: template.action_config.body_html }} />
            </>
          ) : template.action_type === 'twilio.send-sms' ? (
            <>
              <Typography variant="h6" sx={{ mt: 4 }}>
                From: {template.action_config.from_number}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                To: {template.action_config.to_number}
              </Typography>
              <Typography sx={{ marginTop: 2 }} dangerouslySetInnerHTML={{ __html: template.action_config.body_html }} />
            </>
          ) : null}
        </Box>
      </Paper>
    </>
  );
};

export default TemplateDetailsTab;
