import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@mui/material';


const NewUploadDialog = ({openModal, setOpenModal, uploadFile, goToNextStep, selectedFile }) => {
  return (
    <Dialog
  open={openModal}
  onClose={() => setOpenModal(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"New File"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Do you want to upload and store this file in your user files?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => {
      // Lógica para "Upload"
      uploadFile(selectedFile).then(() => {
        setOpenModal(false);
        goToNextStep();
      });
    }}>Upload</Button>
    <Button onClick={() => {
      // Lógica para "Proceed Without Upload"
      setOpenModal(false);
      goToNextStep();
    }} color="primary" autoFocus>
      Proceed Without Upload
    </Button>
    <Button onClick={() => {
      // Lógica para "Cancel"
      setOpenModal(false);
    }} color="secondary">
      Cancel
    </Button>
  </DialogActions>
</Dialog>

  )
}

export default NewUploadDialog