import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, List, ListItem, ListItemText
} from '@mui/material';
import { SemiBold, Bold } from '../../../styled-components/Typography';
import { PrimaryButton, SubSecondaryButton } from '../../../styled-components/Buttons';
import { PiWarningBold } from "react-icons/pi";
import { useAuth } from '../../../contexts/AuthContext';

const AddAccountModal = ({ isOpen, handleClose, handleProceed, handleCancel }) => {
  const { id, uid } = useAuth();  
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Add a New Account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to add a new account and will be redirected to another page.
        </DialogContentText>
        <br/>
        <DialogContentText sx={{
          backgroundColor: 'rgba(255, 0, 0, 0.1)', // Avermelhado e opaco
          padding: '8px', // Espaçamento interno para separar o texto do background
          borderRadius: '4px', // Borda arredondada para estética
          marginTop: '16px', // Margem superior para separar dos outros elementos, se necessário
        }}>
          <SemiBold>Please note: New accounts are not eligible for the 14-day trial. You will need to choose a plan and add a payment method.</SemiBold>
          <br/>
          <br/>
          <div style={{ display: "flex", alignItems: "center" }}>
            <PiWarningBold style={{marginRight: '1rem'}} size={18}/><Bold>Read the instructions below carefully:</Bold><PiWarningBold style={{marginLeft: '1rem'}} size={18}/>
            </div>
        <List>
          <ListItem>
            <ListItemText primary="You will be redirected to the Google login page." />
          </ListItem>
          <ListItem>
            <ListItemText primary="You must choose an account different from the one you are currently using to access Postsheet. Failure to do so will result in an error message and the account creation process will not be completed." />
          </ListItem>
          <ListItem>
            <ListItemText primary="After creating the account, you will be redirected to a page where you can choose a plan and insert a payment method." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Until a payment method is added, you will not be able to use the added account nor will you be able to add any other accounts." />
          </ListItem>
        </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SubSecondaryButton onClick={handleCancel} color="primary">
          Cancel
        </SubSecondaryButton>
        <PrimaryButton onClick={handleProceed} color="primary" variant="contained" autoFocus>
          Proceed
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccountModal;
